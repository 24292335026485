import React, { useState, useEffect } from 'react';
import { useAuth } from '../../AuthContext';
import { useNavigate } from 'react-router-dom';
import Overview from './Overview/Overview';
import MatchesTab from './Matches/MatchesTab';
import LeaguesTab from './Leagues/LeaguesTab';
import TeamsTab from './Teams/TeamsTab';
import UsersTab from './Users/UsersTab';
import TabButton from './Common/TabButton';
import '../../css/style.css';

const AdminPage = () => {
  const { user } = useAuth();
  const [activeTab, setActiveTab] = useState('overview');
  const [activeSubTab, setActiveSubTab] = useState('CreateNewUser');
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect if user is not logged in or has no admin rights
    if (!user || (!user.is_staff && !user.permissions?.is_league_admin)) {
      navigate('/');
    }
  }, [user, navigate]);
  

  const getAvailableTabs = () => {
    if (user?.is_staff) {
      return [
        { id: 'overview', label: 'Översikt' },
        { id: 'matches', label: 'Matcher', defaultSubTab: 'MatchResults' },
        { id: 'leagues', label: 'Tävlingar', defaultSubTab: 'ActiveLeagues' },
        { id: 'teams', label: 'Lag', defaultSubTab: 'CreateNewTeams' },
        { id: 'users', label: 'Användare', defaultSubTab: 'CreateNewUser' }
      ];
    } else {
      // League Admin tabs
      return [
        { id: 'overview', label: 'Översikt' },
        { id: 'matches', label: 'Matcher', defaultSubTab: 'MatchResults' }
      ];
    }
  };

  const tabs = getAvailableTabs();

  const handleTabClick = (tabId, defaultSubTab) => {
    setActiveTab(tabId);
    setActiveSubTab(defaultSubTab);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'overview':
        return <Overview 
          setActiveTab={setActiveTab} 
          setActiveSubTab={setActiveSubTab} 
        />;
      case 'matches':
        return <MatchesTab activeSubTab={activeSubTab} />;
      case 'leagues':
        return <LeaguesTab activeSubTab={activeSubTab} />;
      case 'teams':
        return <TeamsTab activeSubTab={activeSubTab} />;
      case 'users':
        return <UsersTab activeSubTab={activeSubTab} />;
      default:
        return null;
    }
  };

  return (
    <div className="container mx-auto p-6 pt-20">
      <section className='pt-8'>
        <h1 className="text-2xl font-bold mb-4">Administrera</h1>
        <div className="tabs mb-4">
          {tabs.map(tab => (
            <TabButton
              key={tab.id}
              isActive={activeTab === tab.id}
              onClick={() => handleTabClick(tab.id, tab.defaultSubTab)}
              label={tab.label}
            />
          ))}
        </div>
        {renderTabContent()}
      </section>
    </div>
  );
};

export default AdminPage;