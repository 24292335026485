import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import teamlogo from "../images/HCK.png";
import Footer from '../components/Footer';
import TeamPopup from '../components/Teams/TeamPopup';

const PointsTable = () => {
    const { leagueId } = useParams();
    const [leagueName, setLeagueName] = useState('');
    const [loading, setLoading] = useState(true);
    const [groupAData, setGroupAData] = useState([]);
    const [groupBData, setGroupBData] = useState([]);
    const [error, setError] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [teamMatches, setTeamMatches] = useState({});

    useEffect(() => {
        const fetchLeagueName = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/`);
                if (!response.ok) {
                    throw new Error('Failed to fetch league name');
                }
                const data = await response.json();
                setLeagueName(data.name);
            } catch (error) {
                console.error('Error fetching league name:', error);
                setError(error.message);
            }
        };

        const fetchPointsForGroup = async (group) => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/table/?group=${group}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return await response.json();
            } catch (error) {
                console.error(`Error fetching points for group ${group}:`, error);
                setError(error.message);
                return [];
            }
        };

        const fetchMatches = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/matches/`);
                if (!response.ok) throw new Error('Failed to fetch matches');
                const data = await response.json();

                const matchesByTeam = {};
                data.forEach(match => {
                    if (!matchesByTeam[match.team1_name]) matchesByTeam[match.team1_name] = [];
                    if (!matchesByTeam[match.team2_name]) matchesByTeam[match.team2_name] = [];

                    const matchData = {
                        id: match.id,
                        date: match.date,
                        home_team: { name: match.team1_name },
                        away_team: { name: match.team2_name },
                        home_score: match.team1_score,
                        away_score: match.team2_score,
                        status: match.team1_score !== null ? 'completed' : 'scheduled',
                        round_number: match.round_number
                    };

                    matchesByTeam[match.team1_name].push(matchData);
                    matchesByTeam[match.team2_name].push(matchData);
                });

                setTeamMatches(matchesByTeam);
            } catch (error) {
                console.error('Error fetching matches:', error);
            }
        };

        const fetchPoints = async () => {
            try {
                setLoading(true);
                const [groupA, groupB] = await Promise.all([
                    fetchPointsForGroup('A'),
                    fetchPointsForGroup('B')
                ]);

                setGroupAData(groupA);
                setGroupBData(groupB);

                await fetchLeagueName();
                await fetchMatches();
            } catch (error) {
                console.error('Error in fetchPoints:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchPoints();
    }, [leagueId]);

    const handleTeamClick = (team) => {
        setSelectedTeam(team);
    };

    const sortedTeams = (teams) => {
        return teams.sort((a, b) => {
            if (b.points !== a.points) {
                return b.points - a.points;
            }
            return b.stone_difference - a.stone_difference;
        });
    };

    const renderTable = (teams, groupName) => (
        <div className="mb-8">
            <h3 className="text-xl font-bold mb-2">Grupp {groupName}</h3>
            <div className="overflow-x-auto">
                <table className="min-w-full text-sm text-white">
                    <thead className="bg-gray-900">
                        <tr>
                            <th className="bg-red w-10 px-2 py-2 text-left tracking-wider">#</th>
                            <th className="px-2 py-2 text-left tracking-wider">Lag</th>
                            <th className="w-36 px-2 py-2 text-center tracking-wider">
                                <span className="hidden md:inline">Spelade Matcher</span>
                                <span className="md:hidden">SM</span>
                            </th>
                            <th className="w-32 px-2 py-2 text-center tracking-wider">
                                <span className="hidden md:inline">Stenskillnad</span>
                                <span className="md:hidden">SS</span>
                            </th>
                            <th className="w-24 px-2 py-2 text-center tracking-wider">
                                <span className="hidden md:inline">Poäng</span>
                                <span className="md:hidden">P</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-gray-500">
                        {sortedTeams(teams).map((team, index) => (
                            <tr
                                key={team.id}
                                className={`border-b hover:bg-gray-900 cursor-pointer ${index % 2 === 0 ? 'bg-gray-800' : 'bg-gray-700'}`}
                                onClick={() => handleTeamClick(team)}
                            >
                                <td className="py-2 px-1 w-10">{index + 1}</td>
                                <td className="flex items-center px-2 py-3 whitespace-nowrap">
                                    <img className="w-5" src={teamlogo} alt={team.name} />
                                    <span className="ml-2 font-medium truncate max-w-[120px] md:max-w-none">
                                        {team.name}
                                    </span>
                                </td>
                                <td className="w-36 px-2 py-3 whitespace-nowrap text-center">{team.matches_played}</td>
                                <td className="w-32 px-2 py-3 whitespace-nowrap text-center">{team.stone_difference}</td>
                                <td className="w-24 px-2 py-3 whitespace-nowrap text-center">{team.points}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );

    if (loading) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <img
                    src={require('../images/curling.gif')}
                    alt="Loading..."
                    className="w-24 h-24"
                />
            </div>
        );
    }

    if (error){
        return <div className="flex justify-center items-center min-h-screen">Ett fel har uppstått: {error}</div>;
    }

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col items-center pt-10">
            <main className="flex-grow container mx-auto px-2 md:px-4 py-16 md:py-16">
                <h2 className="text-2xl font-bold mb-6 text-gray-800">{leagueName}</h2>

                {renderTable(groupAData, 'A')}
                {renderTable(groupBData, 'B')}

                <div className="md:hidden mt-4 text-sm text-gray-600">
                    <p>SM* (Spelade matcher)</p>
                    <p>SS* (Stenskillnad)</p>
                </div>

                {selectedTeam && (
                    <TeamPopup
                        team={selectedTeam}
                        matches={teamMatches[selectedTeam.name] || []}
                        onClose={() => setSelectedTeam(null)}
                    />
                )}
            </main>
            <Footer />
        </div>
    );
};

export default PointsTable;