import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Save, ArrowLeft, Trophy, Users, Calendar, ClipboardList, Clock } from 'lucide-react';

const ManageLeague = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [league, setLeague] = useState({
        name: '',
        organizer: '',
        info: '',
        start_date: '',
        end_date: '',
        active: true,
        quarterfinal_date: '',
        quarterfinal_time: '',
        semifinal_date: '',
        semifinal_time: '',
        final_date: '',
        final_time: '',
        loser_round1_date: '',
        loser_round1_time: '',
        loser_round2_date: '',
        loser_round2_time: '',
        loser_final_date: '',
        loser_final_time: '',
        teamscount: 0,
        participants: 0,
        gamesplayed: 0,
        mvp: ''
    });

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const getCsrfToken = () => {
        const match = document.cookie.match(/csrftoken=([^;]+)/);
        return match ? match[1] : '';
    };

    useEffect(() => {
        const fetchLeague = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/leagues/${id}/`);
                if (!response.ok) throw new Error('Kunde inte hämta ligainformation');
                const data = await response.json();

                // Split datetime strings into separate date and time
                const splitDateTime = (dateTimeStr) => {
                    if (!dateTimeStr) return { date: '', time: '' };
                    const [datePart, timePart] = dateTimeStr.split('T');
                    return {
                        date: datePart,
                        time: timePart ? timePart.substring(0, 5) : ''  // Extract HH:mm
                    };
                };

                // Process each datetime field
                const quarterfinal = splitDateTime(data.quarterfinal_datetime);
                const semifinal = splitDateTime(data.semifinal_datetime);
                const final = splitDateTime(data.final_datetime);
                const loserRound1 = splitDateTime(data.loser_round1_datetime);
                const loserRound2 = splitDateTime(data.loser_round2_datetime);
                const loserFinal = splitDateTime(data.loser_final_datetime);

                setLeague({
                    name: data.name || '',
                    organizer: data.organizer || '',
                    leagueinfo: data.info || '',
                    start_date: data.start_date || '',
                    end_date: data.end_date || '',
                    active: data.active ?? true,
                    quarterfinal_date: quarterfinal.date,
                    quarterfinal_time: quarterfinal.time,
                    semifinal_date: semifinal.date,
                    semifinal_time: semifinal.time,
                    final_date: final.date,
                    final_time: final.time,
                    loser_round1_date: loserRound1.date,
                    loser_round1_time: loserRound1.time,
                    loser_round2_date: loserRound2.date,
                    loser_round2_time: loserRound2.time,
                    loser_final_date: loserFinal.date,
                    loser_final_time: loserFinal.time,
                    teamscount: data.teamscount || 0,
                    participants: data.participants || 0,
                    gamesplayed: data.gamesplayed || 0,
                    mvp: data.mvp || ''
                });
            } catch (err) {
                setError('Ett fel uppstod vid hämtning av liga');
                console.error(err);
            }
        };

        if (id) fetchLeague();
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Combine date and time fields before sending to API
            const combineDateTime = (date, time) => {
                return date && time ? `${date}T${time}` : '';
            };

            const submissionData = {
                ...league,
                quarterfinal_datetime: combineDateTime(league.quarterfinal_date, league.quarterfinal_time),
                semifinal_datetime: combineDateTime(league.semifinal_date, league.semifinal_time),
                final_datetime: combineDateTime(league.final_date, league.final_time),
                loser_round1_datetime: combineDateTime(league.loser_round1_date, league.loser_round1_time),
                loser_round2_datetime: combineDateTime(league.loser_round2_date, league.loser_round2_time),
                loser_final_datetime: combineDateTime(league.loser_final_date, league.loser_final_time)
            };

            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/leagues/manage/${id}/`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCsrfToken(),
                    'Authorization': `Token ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(submissionData),
                credentials: 'include'
            });

            if (!response.ok) throw new Error('Kunde inte uppdatera ligan');
            setSuccess('Ligan har uppdaterats');
        } catch (err) {
            setError('Ett fel uppstod vid uppdatering av ligan');
            console.error(err);
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setLeague(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked :
                type === 'number' ? (value === '' ? 0 : Number(value)) :
                    value
        }));
    };

    // Helper component for date-time input pairs
    const DateTimeInputPair = ({ dateValue, timeValue, dateName, timeName, label }) => (
        <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
                <Trophy className="inline-block w-4 h-4 mr-1" />
                {label}
            </label>
            <div className="flex gap-2">
                <div className="flex-1">
                    <div className="flex items-center">
                        <Calendar className="w-4 h-4 mr-2 text-gray-500" />
                        <input
                            type="date"
                            name={dateName}
                            value={dateValue}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                </div>
                <div className="flex-1">
                    <div className="flex items-center">
                        <Clock className="w-4 h-4 mr-2 text-gray-500" />
                        <input
                            type="time"
                            name={timeName}
                            value={timeValue}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                </div>
            </div>
        </div>
    );


    return (
        <div className="max-w-6xl mx-auto py-16 p-4">
            <div className="bg-gray-100 rounded-lg shadow-md p-6">
                <div className="mb-6 pt-5">
                    <h1 className="text-2xl font-bold mb-2">Hantera Tävling</h1>
                    <div className="h-1 w-20 bg-gray-500"></div>
                </div>

                <form onSubmit={handleSubmit} className="space-y-6">
                    {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">{error}</div>}
                    {success && <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded">{success}</div>}

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Namn</label>
                            <input
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                name="name"
                                value={league.name}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Organisatör</label>
                            <input
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                name="organizer"
                                value={league.organizer}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Ligainformation</label>
                        <textarea
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-24"
                            name="leagueinfo"
                            value={league.leagueinfo}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                <Calendar className="inline-block w-4 h-4 mr-1" />
                                Startdatum
                            </label>
                            <input
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                type="date"
                                name="start_date"
                                value={league.start_date}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                <Calendar className="inline-block w-4 h-4 mr-1" />
                                Slutdatum
                            </label>
                            <input
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                type="date"
                                name="end_date"
                                value={league.end_date}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>

                    <div className="flex flex-col space-y-2">
                        <label className="flex items-center space-x-2">
                            <input
                                type="checkbox"
                                className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                name="active"
                                checked={league.active}
                                onChange={handleChange}
                            />
                            <span className="text-sm font-medium text-gray-700">Aktiv</span>
                        </label>
                    </div>

                    {/* A-Slutspel Section */}
                    <div className="space-y-4">
                        <h3 className="text-lg font-medium text-gray-700">A-Slutspel</h3>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            <DateTimeInputPair
                                dateValue={league.quarterfinal_date}
                                timeValue={league.quarterfinal_time}
                                dateName="quarterfinal_date"
                                timeName="quarterfinal_time"
                                label="Kvartsfinal"
                            />
                            <DateTimeInputPair
                                dateValue={league.semifinal_date}
                                timeValue={league.semifinal_time}
                                dateName="semifinal_date"
                                timeName="semifinal_time"
                                label="Semifinal"
                            />
                            <DateTimeInputPair
                                dateValue={league.final_date}
                                timeValue={league.final_time}
                                dateName="final_date"
                                timeName="final_time"
                                label="Final"
                            />
                        </div>
                    </div>

                    {/* B-Slutspel Section */}
                    <div className="space-y-4">
                        <h3 className="text-lg font-medium text-gray-700">B-Slutspel</h3>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            <DateTimeInputPair
                                dateValue={league.loser_round1_date}
                                timeValue={league.loser_round1_time}
                                dateName="loser_round1_date"
                                timeName="loser_round1_time"
                                label="Kvartsfinal"
                            />
                            <DateTimeInputPair
                                dateValue={league.loser_round2_date}
                                timeValue={league.loser_round2_time}
                                dateName="loser_round2_date"
                                timeName="loser_round2_time"
                                label="Semifinal"
                            />
                            <DateTimeInputPair
                                dateValue={league.loser_final_date}
                                timeValue={league.loser_final_time}
                                dateName="loser_final_date"
                                timeName="loser_final_time"
                                label="Final"
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                <Users className="inline-block w-4 h-4 mr-1" />
                                Antal lag
                            </label>
                            <input
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                type="number"
                                name="teamscount"
                                value={league.teamscount}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                <Users className="inline-block w-4 h-4 mr-1" />
                                Antal deltagare
                            </label>
                            <input
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                type="number"
                                name="participants"
                                value={league.participants}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                <ClipboardList className="inline-block w-4 h-4 mr-1" />
                                Spelade matcher
                            </label>
                            <input
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                type="number"
                                name="gamesplayed"
                                value={league.gamesplayed}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                <Trophy className="inline-block w-4 h-4 mr-1" />
                                MVP
                            </label>
                            <input
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                name="mvp"
                                value={league.mvp}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className="flex justify-end space-x-4 pt-4">
                        <button
                            type="button"
                            onClick={() => navigate(-1)}
                            className="flex items-center px-4 py-2 border border-gray-300 rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            <ArrowLeft className="w-4 h-4 mr-2" />
                            Avbryt
                        </button>
                        <button
                            type="submit"
                            className="flex items-center px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            <Save className="w-4 h-4 mr-2" />
                            Spara ändringar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ManageLeague;