import React, { useEffect, useState } from 'react';
import { useAuth } from '../../AuthContext';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Plus, X } from 'lucide-react';

const AddLeagueTeams = () => {
    const { leagueId } = useParams();
    const [teams, setTeams] = useState([]);
    const [league, setLeague] = useState(null);
    const [formData, setFormData] = useState({
        selectedTeams: [{
            teamId: '',
            group: 'A',
            groupName: ''
        }]
    });
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { user } = useAuth();  
    const navigate = useNavigate();

    useEffect(() => {
        if (!user?.is_staff) {
          navigate('/');
        }
      }, [user, navigate]);

    useEffect(() => {

        const fetchData = async () => {
            try {
                const [teamsResponse, leaguesResponse] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_API_URL}/api/teams/`, {
                        headers: {
                            'Authorization': `Token ${localStorage.getItem('authToken')}`
                        }
                    }),
                    axios.get(`${process.env.REACT_APP_API_URL}/api/leagues/${leagueId}/`, {
                        headers: {
                            'Authorization': `Token ${localStorage.getItem('authToken')}`
                        }
                    })
                ]);
                
                setTeams(teamsResponse.data);
                setLeague({
                    name: leaguesResponse.data.name || '',
                });
    
            } catch (error) {
                setError('Kunde inte hämta lag eller ligainformation');
                console.error(error);
            }
        };
        
        if (leagueId) fetchData();
    }, [leagueId]);

    const handleTeamChange = (index, field, value) => {
        const newSelectedTeams = [...formData.selectedTeams];
        newSelectedTeams[index] = {
            ...newSelectedTeams[index],
            [field]: value
        };
        setFormData(prev => ({
            ...prev,
            selectedTeams: newSelectedTeams
        }));
    };

    const addTeam = () => {
        setFormData(prev => ({
            ...prev,
            selectedTeams: [...prev.selectedTeams, { teamId: '', group: 'A', groupName: '' }]
        }));
    };

    const removeTeam = (index) => {
        if (formData.selectedTeams.length > 1) {
            const newSelectedTeams = formData.selectedTeams.filter((_, i) => i !== index);
            setFormData(prev => ({
                ...prev,
                selectedTeams: newSelectedTeams
            }));
        }
    };

    const checkExistingTeams = async (teamsToSubmit) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/leagues/${leagueId}/teams/`,
                {
                    headers: {
                        'Authorization': `Token ${localStorage.getItem('authToken')}`
                    }
                }
            );
            
            // Kontrollera om response.data är en array
            const existingTeams = Array.isArray(response.data) 
                ? response.data.map(relation => relation.team.id)
                : [];
                
            const duplicateTeams = teamsToSubmit.filter(teamId => 
                existingTeams.includes(teamId)
            );
            
            return duplicateTeams;
        } catch (error) {
            console.error('Error checking existing teams:', error);
            return [];
        }
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
    
        try {
            const teamsToSubmit = formData.selectedTeams
                .filter(team => team.teamId !== '')
                .map(team => parseInt(team.teamId));
    
            // Kontrollera om några lag redan finns i ligan
            const duplicateTeams = await checkExistingTeams(teamsToSubmit);
            if (duplicateTeams.length > 0) {
                setError('Följande lag är redan tillagda i denna liga');
                return;
            }
    
            const requestData = {
                teams: teamsToSubmit,
                league: parseInt(leagueId),
                group: formData.selectedTeams[0]?.group || 'A',
                groupid: formData.selectedTeams[0]?.groupName || ''
            };
        
            await axios.post(
                `${process.env.REACT_APP_API_URL}/api/team-league-relations/`, 
                requestData,
                {
                    headers: {
                        'Authorization': `Token ${localStorage.getItem('authToken')}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
    
            setSuccessMessage('Lagen har lagts till i tävlingen');
            setFormData({
                selectedTeams: [{ teamId: '', group: 'A', groupName: '' }]
            });
    
        } catch (error) {
            console.error('Error data:', error.response?.data);
            
            // Kontrollera om det är ett unikt begränsningsfel
            if (error.response?.data?.error?.includes('redan tillagda')) {
                setError('Ett eller flera av dessa lag är redan tillagda i denna liga');
            } else {
                setError('Kunde inte lägga till lagen: ' + (error.response?.data?.error || 'Ett fel uppstod'));
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col items-center pt-16">
            <main className="w-full max-w-7xl mx-auto px-4 py-16 pt-16 space-y-16 flex-grow">
                <section className="">
                    <h2 className="text-2xl font-bold mb-6">
                        Lägg till lag i {league?.name || 'tävlingen'}
                    </h2>

                    <div className="bg-white rounded-lg shadow-md">
                        <div className="p-4">
                            <form onSubmit={handleSubmit} className="space-y-6">
                                <div className="space-y-6">
                                    {formData.selectedTeams.map((team, index) => (
                                        <div key={index} className="flex flex-col sm:grid sm:grid-cols-4 sm:gap-4 space-y-4 sm:space-y-0">
                                            <div className="w-full sm:col-span-2">
                                                <label className="block text-sm font-medium text-gray-700 mb-1 sm:hidden">
                                                    Välj lag
                                                </label>
                                                <select
                                                    value={team.teamId}
                                                    onChange={(e) => handleTeamChange(index, 'teamId', e.target.value)}
                                                    className="w-full rounded-md border border-gray-300 p-2"
                                                >
                                                    <option value="">Välj lag</option>
                                                    {teams.map((t) => (
                                                        <option key={t.id} value={t.id}>
                                                            {t.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="w-full sm:col-span-1">
                                                <label className="block text-sm font-medium text-gray-700 mb-1 sm:hidden">
                                                    Välj grupp
                                                </label>
                                                <select
                                                    value={team.group}
                                                    onChange={(e) => handleTeamChange(index, 'group', e.target.value)}
                                                    className="w-full rounded-md border border-gray-300 p-2"
                                                >
                                                    <option value="A">Grupp A</option>
                                                    <option value="B">Grupp B</option>
                                                </select>
                                            </div>

                                            <div className="w-full sm:col-span-1">
                                                <label className="block text-sm font-medium text-gray-700 mb-1 sm:hidden">
                                                    Gruppnamn
                                                </label>
                                                <div className="flex items-center space-x-2">
                                                    <input
                                                        type="text"
                                                        value={team.groupName}
                                                        onChange={(e) => handleTeamChange(index, 'groupName', e.target.value)}
                                                        placeholder="Gruppnamn"
                                                        className="flex-1 rounded-md border border-gray-300 p-2"
                                                    />
                                                    {index > 0 && (
                                                        <button
                                                            type="button"
                                                            onClick={() => removeTeam(index)}
                                                            className="p-2 text-red-600 hover:text-red-800"
                                                        >
                                                            <X size={20} />
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                            
                                            {index < formData.selectedTeams.length - 1 && (
                                                <div className="border-b border-gray-200 w-full sm:hidden"></div>
                                            )}
                                        </div>
                                    ))}
                                </div>

                                <div className="flex flex-col sm:flex-row justify-between pt-4 space-y-4 sm:space-y-0">
                                    <button
                                        type="button"
                                        onClick={addTeam}
                                        className="flex items-center space-x-2 text-blue-600 hover:text-blue-800"
                                    >
                                        <Plus size={20} />
                                        <span>Lägg till fler lag</span>
                                    </button>

                                    <button
                                        type="submit"
                                        disabled={isSubmitting}
                                        className="w-full sm:w-auto bg-gray-600 text-white px-4 py-2 rounded-md hover:bg-gray-800 disabled:bg-gray-400"
                                    >
                                        {isSubmitting ? 'Sparar...' : 'Spara'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>

                    {successMessage && (
                        <div className="mt-4 p-4 bg-green-100 text-green-700 rounded-md">
                            {successMessage}
                        </div>
                    )}
                    {error && (
                        <div className="mt-4 p-4 bg-red-100 text-red-700 rounded-md">
                            {error}
                        </div>
                    )}

                </section>
            </main>
            <footer className="bg-gray-800 text-white text-center p-4 w-full mt-auto">
                <p>&copy; 2024 SweepScore</p>
            </footer>
        </div>
    );
}

export default AddLeagueTeams;