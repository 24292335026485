// frontend/app.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import NavBar from './components/Navbar';
import Home from './pages/Home';
import Teams from './pages/Teams';
import LeagueDetail from './pages/LeagueDetail';
import PointsTable from './pages/PointsTable';
import MatchesPage from './pages/MatchesPage';
import MyCompetitions from './pages/MyCompetitions';
import MyMatches from './pages/MyMatches';
import LoginPage from './pages/Login';
import MyTeamPage from './pages/MyTeam';
import ProfilePage from './pages/ProfilePage';
import AdminPage from './pages/adminpage/AdminPage';
import Playoff from './pages/Playoffs';
import ManageLeague from './pages/adminpage/Leagues/ManageLeagues';
import AddLeagueTeams from './components/activeleagues/AddTeams';
import LeagueSchedule from './pages/LeagueSchedule'
//import { useAuth } from './AuthContext';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  return (
    <AuthProvider>
      <Router>
      <Routes>
        <Route 
          path="/" 
          element={
            <>
            <NavBar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} user={user} withLeagueLinks={false} />
            <Home />   
            </>
          }
        />
        <Route 
          path="/league/:id" 
          element={
            <>
              <NavBar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} user={user} withLeagueLinks={true} />
              <LeagueDetail />
            </>
          } 
        />
        <Route 
          path="/league/:leagueId/teams" 
          element={
            <>
              <NavBar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} user={user} withLeagueLinks={true} />
              <Teams />
            </>
          } 
        />
        <Route 
          path="/league/:leagueId/table" 
          element={
            <>
              <NavBar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} user={user} withLeagueLinks={true} />
              <PointsTable />
            </>
          } 
        />
        <Route 
          path="/league/:leagueId/matches" 
          element={
            <>
              <NavBar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} user={user} withLeagueLinks={true} />
              <MatchesPage />
            </>
          } 
        />
        <Route 
          path="/league/:leagueId/playoff" 
          element={
            <>
              <NavBar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} user={user} withLeagueLinks={true} />
              <Playoff />
            </>
          } 
        />
        <Route
          path="/league/:leagueId/schedule"
          element={
            <>
              <NavBar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} user={user} withLeagueLinks={true} />
              <LeagueSchedule />
            </>
          }

        />
        <Route 
          path="/login" 
          element={
            <>
              <NavBar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} user={user} />
              <LoginPage setIsLoggedIn={setIsLoggedIn} setUser={setUser} /> 
            </>
          }
        />
        <Route 
          path="/profile" 
          element={
            <>
            <NavBar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} user={user} withLeagueLinks={false} />
            <ProfilePage />   
            </>
          }
        />
        <Route 
          path="/my-team" 
          element={
            <>
            <NavBar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} user={user} withLeagueLinks={false} />
            <MyTeamPage />   
            </>
            }
        />
        <Route 
          path="/my-matches" 
          element={
            <>
            <NavBar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} user={user} withLeagueLinks={false} />
            <MyMatches />   
            </>
            }
        />
        <Route 
          path="/my-competitions" 
          element={
            <>
            <NavBar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} user={user} withLeagueLinks={false} />
            <MyCompetitions />   
            </>
            }
        />
        <Route
          path="/admin"
          element={
            <>
            <NavBar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} user={user} withLeagueLinks={true} />
            <AdminPage />
            </>
          }
        />
        <Route
          path="/admin/manage-league/:id"
          element={
            <>
            <NavBar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} user={user} withLeagueLinks={true} />
            <ManageLeague />
            </>
          }
        />
        <Route
          path="/league/:leagueId/add-teams"
          element={
            <>
            <NavBar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} user={user} withLeagueLinks={true} />
            <AddLeagueTeams />
            </>
          }
        />
      </Routes>
    </Router>
    </AuthProvider>
  );  
};

export default App;
