import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import defaultAvatar from '../images/Player1.png';
import { Camera, Key, User, Mail, Save } from 'lucide-react';
import Footer from '../components/Footer';

const ProfilePage = () => {
    const { user, setUser } = useAuth();
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [isEditingPassword, setIsEditingPassword] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [passwordForm, setPasswordForm] = useState({
        newPassword: '',
        confirmPassword: ''
    });

    useEffect(() => {
        if (user) {
            setUsername(user.username || '');
            setEmail(user.email || '');
        }
    }, [user]);

    const handleProfileUpdate = async (e) => {
        e.preventDefault();
        setError('');
        setSuccessMessage('');

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/users/update/', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({ username, email }),
            });

            if (response.ok) {
                const updatedUser = await response.json();
                setUser(updatedUser);
                setSuccessMessage('Profilen har uppdaterats!');
            } else {
                const errorData = await response.json();
                if (errorData.username) {
                    setError('Användarnamnet finns redan');
                } else if (errorData.email) {
                    setError('E-postadressen finns redan');
                } else {
                    setError('Ett fel uppstod vid uppdatering av profilen');
                }
            }
        } catch (error) {
            setError('Ett nätverksfel uppstod');
        }
    };

    const handlePasswordUpdate = async (e) => {
        e.preventDefault();
        setError('');
        setSuccessMessage('');

        if (passwordForm.newPassword !== passwordForm.confirmPassword) {
            setError('Lösenorden matchar inte');
            return;
        }

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/users/update/', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({ password: passwordForm.newPassword }),
            });

            if (response.ok) {
                setSuccessMessage('Lösenordet har uppdaterats!');
                setPasswordForm({
                    newPassword: '',
                    confirmPassword: ''
                });
                setIsEditingPassword(false);
            } else {
                const errorData = await response.json();
                setError(errorData.message || 'Ett fel uppstod vid uppdatering av lösenordet');
            }
        } catch (error) {
            setError('Ett nätverksfel uppstod');
        }
    };

    if (!user) {
        return <p>Ingen användare inloggad.</p>;
    }

    return (
        <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 flex flex-col">
            <div className="flex justify-center items-center py-6">
                <div className="container mx-auto px-2 py-20">
                    <div className="max-w-xl mx-auto bg-gray-200 rounded-2xl shadow-lg overflow-hidden">
                        

                        {error && (
                            <div className="px-8 py-4 bg-red-50 border-l-4 border-red-500 text-red-700">
                                {error}
                            </div>
                        )}

                        {successMessage && (
                            <div className="px-8 py-4 bg-green-50 border-l-4 border-green-500 text-green-700">
                                {successMessage}
                            </div>
                        )}

                        <div className="p-8">
                            <div className="flex items-center justify-center mb-8">
                                <div className="relative">
                                    <img
                                        src={user?.avatar || defaultAvatar}
                                        alt="Profile"
                                        className="w-32 h-32 rounded-full object-cover border-4 border-white shadow-lg"
                                    />
                                    <button className="absolute bottom-0 right-0 bg-gray-800 p-2 rounded-full text-white hover:bg-red-500 transition-colors">
                                        <Camera size={20} />
                                    </button>
                                </div>
                            </div>


                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">Förnamn</label>
                                    <input
                                        type="text"
                                        defaultValue={user.first_name}
                                        className="w-full px-4 py-2 border border-gray-800 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                        disabled={true}
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">Efternamn</label>
                                    <input
                                        type="text"
                                        defaultValue={user.last_name}
                                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-gray-800 focus:border-transparent mb-4"
                                        disabled={true}
                                    />
                                </div>
                            </div>

                            <form onSubmit={handleProfileUpdate} className="space-y-6">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Användarnamn
                                    </label>
                                    <div className="relative">
                                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                            <User className="h-5 w-5 text-gray-400" />
                                        </div>
                                        <input
                                            type="text"
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                            className="w-full pl-10 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-gray-800 focus:border-transparent"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Epost
                                    </label>
                                    <div className="relative">
                                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                            <Mail className="h-5 w-5 text-gray-400" />
                                        </div>
                                        <input
                                            type="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            className="w-full pl-10 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-gray-800 focus:border-transparent"
                                        />
                                    </div>
                                </div>

                                <div className="flex justify-end">
                                    <button
                                        type="submit"
                                        className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-lg text-white bg-gray-600 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
                                    >
                                        <Save className="w-5 h-5 mr-2" />
                                        Spara ändringar
                                    </button>
                                </div>
                            </form>

                            <div className="mt-10 pt-10 border-t border-gray-800">
                                <div className="flex items-center justify-between mb-6">
                                    <h2 className="text-xl font-semibold text-gray-800">Lösenord</h2>
                                    <button
                                        onClick={() => setIsEditingPassword(!isEditingPassword)}
                                        className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 transition-colors"
                                    >
                                        <Key className="w-4 h-4 mr-2" />
                                        {isEditingPassword ? 'Avbryt' : 'Byt lösenord'}
                                    </button>
                                </div>

                                {isEditingPassword && (
                                    <form onSubmit={handlePasswordUpdate} className="space-y-4">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                                Nytt lösenord
                                            </label>
                                            <input
                                                type="password"
                                                value={passwordForm.newPassword}
                                                onChange={(e) => setPasswordForm({ ...passwordForm, newPassword: e.target.value })}
                                                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                                Bekräfta lösenordet
                                            </label>
                                            <input
                                                type="password"
                                                value={passwordForm.confirmPassword}
                                                onChange={(e) => setPasswordForm({ ...passwordForm, confirmPassword: e.target.value })}
                                                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                                required
                                            />
                                        </div>
                                        <div className="flex justify-end">
                                            <button
                                                type="submit"
                                                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-lg text-white bg-gray-600 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
                                            >
                                                Uppdatera lösenordet
                                            </button>
                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ProfilePage;