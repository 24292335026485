import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import logo from '../images/Sweepscore_white.png';
import { Menu, X, Mail } from 'lucide-react';

const NavBar = ({ withLeagueLinks }) => {
    const { isLoggedIn, setIsLoggedIn, user } = useAuth();
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [showAvatarMenu, setShowAvatarMenu] = useState(false);
    const [showBanner, setShowBanner] = useState(true);
    const { id, leagueId } = useParams();
    const currentLeagueId = id || leagueId;
    const navigate = useNavigate();
    const location = useLocation();

    // Refs för menyerna
    const mobileMenuRef = useRef(null);
    const avatarMenuRef = useRef(null);
    const hamburgerButtonRef = useRef(null);
    const avatarButtonRef = useRef(null);

    // Kontrollera om bannern tidigare har stängts
    useEffect(() => {
        const bannerClosed = localStorage.getItem('resultReporterBannerClosed');
        if (bannerClosed) {
            setShowBanner(false);
        }
    }, []);

    const handleCloseBanner = () => {
        setShowBanner(false);
        localStorage.setItem('resultReporterBannerClosed', 'true');
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            // Kontrollera klick utanför mobilmenyn
            if (showMobileMenu &&
                mobileMenuRef.current &&
                !mobileMenuRef.current.contains(event.target) &&
                !hamburgerButtonRef.current.contains(event.target)) {
                setShowMobileMenu(false);
            }

            // Kontrollera klick utanför avatarmenyn
            if (showAvatarMenu &&
                avatarMenuRef.current &&
                !avatarMenuRef.current.contains(event.target) &&
                !avatarButtonRef.current.contains(event.target)) {
                setShowAvatarMenu(false);
            }
        };

        // Lägg till event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showMobileMenu, showAvatarMenu]);

    const handleLogout = () => {
        localStorage.removeItem('authToken');
        setIsLoggedIn(false);
        navigate('/');
    };

    const handleLinkClick = () => {
        setShowMobileMenu(false);
        setShowAvatarMenu(false);
    };

    const isHomePage = location.pathname === '/';
    const isProfilePage = location.pathname === '/profile';
    const isMyTeamPage = location.pathname === '/my-team';
    const isMyCompetitionPage = location.pathname === '/my-competitions';
    const isMyMatchesPage = location.pathname === '/my-matches';
    const isAdminPage = location.pathname === '/admin';
    const isProfileOrMyTeamPage = isProfilePage || isMyTeamPage || isMyCompetitionPage || isAdminPage || isMyMatchesPage || isHomePage;

    return (
        <>
            {/* Huvudnavigering */}
            <nav className="bg-gray-800 text-white p-4 flex justify-between items-center shadow-lg fixed w-full z-10">
                {/* Vänster del: Logotyp och namn */}
                <div className="flex items-center">
                    <Link to="/">
                        <img src={logo} alt="SweepScore Logo" className="w-12 h-12 mr-2 mb-1" />
                    </Link>
                    <Link to="/">
                        <h1 className="text-xl font-semibold">SweepScore</h1>
                    </Link>
                </div>

                {/* Center: SEO-rubrik eller länkar */}
                <div className="hidden md:flex justify-center flex-grow">
                    {!isLoggedIn && isHomePage ? (
                        <h2 className="text-lg white font-medium">
                            Digital resultatportal för curlingklubbar
                        </h2>
                    ) : (
                        <ul className="flex space-x-4 items-center justify-center">
                            {withLeagueLinks && currentLeagueId && (
                                <>
                                    <li><Link to={`/league/${currentLeagueId}`} className="hover:text-gray-400">Översikt</Link></li>
                                    <li><Link to={`/league/${currentLeagueId}/schedule`} className="hover:text-gray-400">Spelschema</Link></li>
                                    <li><Link to={`/league/${currentLeagueId}/matches`} className="hover:text-gray-400">Matcher</Link></li>
                                    <li><Link to={`/league/${currentLeagueId}/table`} className="hover:text-gray-400">Tabell</Link></li>
                                    <li><Link to={`/league/${currentLeagueId}/playoff`} className="hover:text-gray-400">Slutspel</Link></li>
                                    <li><Link to={`/league/${currentLeagueId}/teams`} className="hover:text-gray-400">Lag</Link></li>
                                </>
                            )}
                            {isLoggedIn && isProfileOrMyTeamPage && (
                                <>
                                    <li><Link to="/profile" className="hover:text-gray-400">Min profil</Link></li>
                                    <li><Link to="/my-team" className="hover:text-gray-400">Mitt lag</Link></li>
                                    <li><Link to="/my-competitions" className="hover:text-gray-400">Mina tävlingar</Link></li>
                                    <li><Link to="/my-matches" className="hover:text-gray-400">Mina matcher</Link></li>
                                    {(user?.is_staff || user?.permissions?.is_league_admin) && (
                                        <li><Link to="/admin" className="hover:text-gray-400">Admin</Link></li>
                                    )}
                                </>
                            )}
                        </ul>
                    )}
                </div>

                {/* Höger del: Hamburger Menu och Avatar */}
                <div className="flex items-center space-x-4">
                    {/* Hamburger Menu för League länkar (endast mobil) */}
                    {withLeagueLinks && currentLeagueId && (
                        <div className="md:hidden">
                            <button
                                ref={hamburgerButtonRef}
                                className="cursor-pointer"
                                onClick={() => setShowMobileMenu(!showMobileMenu)}
                            >
                                <Menu size={24} />
                            </button>
                        </div>
                    )}

                    {/* Avatar/Login */}
                    {isLoggedIn ? (
                        <div className="relative">
                            <button
                                ref={avatarButtonRef}
                                className="flex items-center cursor-pointer focus:outline-none"
                                onClick={() => setShowAvatarMenu(!showAvatarMenu)}
                            >
                                <img
                                    src={user?.avatar || logo}
                                    alt="User"
                                    className="w-10 h-10 rounded-full object-cover border-2 border-white"
                                />
                            </button>
                            {/* Avatar dropdown menu */}
                            {showAvatarMenu && (
                                <div ref={avatarMenuRef} className="absolute right-0 mt-2 w-48 bg-gray-800 rounded-lg shadow-lg p-4">
                                    <ul className="space-y-3">
                                        <>
                                            <li><Link to="/profile" className="block hover:text-gray-400" onClick={handleLinkClick}>Min profil</Link></li>
                                            <li><Link to="/my-team" className="block hover:text-gray-400" onClick={handleLinkClick}>Mitt lag</Link></li>
                                            <li><Link to="/my-competitions" className="block hover:text-gray-400" onClick={handleLinkClick}>Mina tävlingar</Link></li>
                                            <li><Link to="/my-matches" className="block hover:text-gray-400" onClick={handleLinkClick}>Mina matcher</Link></li>
                                            {(user?.is_staff || user?.permissions?.is_league_admin) && (
                                                <li><Link to="/admin" className="block hover:text-gray-400" onClick={handleLinkClick}>Admin</Link></li>
                                            )}
                                            <li className="border-t border-gray-600 my-2"></li>
                                            <li>
                                                <button
                                                    className="w-full text-left text-white hover:text-gray-400"
                                                    onClick={() => {
                                                        handleLogout();
                                                        handleLinkClick();
                                                    }}
                                                >
                                                    Logga ut
                                                </button>
                                            </li>
                                        </>
                                    </ul>
                                </div>
                            )}
                        </div>
                    ) : (
                        <Link to="/login" className="hover:text-gray-400">Logga in</Link>
                    )}
                </div>
            </nav>

            {/* Resultatrapporterings-banner */}
            {showBanner && (
                <div className="fixed top-20 w-full bg-blue-600 text-white py-3 px-4 flex items-center justify-between z-9">
                    <div className="flex items-center space-x-2 mx-auto">
                        <Mail size={20} />
                        <span>
                            Vill du hjälpa till att rapportera ditt lags matchresultat?
                            <a
                                href="mailto:tobbe.stenberg@hotmail.com"
                                className="underline ml-2 hover:text-blue-200"
                            >
                                Kontakta mig för inloggning!
                            </a>
                        </span>
                    </div>
                    <button
                        onClick={handleCloseBanner}
                        className="text-white hover:text-blue-200 p-1"
                        aria-label="Stäng banner"
                    >
                        <X size={20} />
                    </button>
                </div>
            )}

            {/* Mobil meny för league länkar */}
            {showMobileMenu && withLeagueLinks && currentLeagueId && (
                <div ref={mobileMenuRef} className="md:hidden fixed top-16 right-4 bg-gray-800 rounded-lg shadow-lg p-4 w-48 z-50 text-white">
                    <ul className="space-y-2">
                        <li><Link to={`/league/${currentLeagueId}`} className="block hover:text-gray-400" onClick={handleLinkClick}>Översikt</Link></li>
                        <li><Link to={`/league/${currentLeagueId}/schedule`} className="block hover:text-gray-400" onClick={handleLinkClick}>Spelschema</Link></li>
                        <li><Link to={`/league/${currentLeagueId}/matches`} className="block hover:text-gray-400" onClick={handleLinkClick}>Matcher</Link></li>
                        <li><Link to={`/league/${currentLeagueId}/table`} className="block hover:text-gray-400" onClick={handleLinkClick}>Tabell</Link></li>
                        <li><Link to={`/league/${currentLeagueId}/playoff`} className="block hover:text-gray-400" onClick={handleLinkClick}>Slutspel</Link></li>
                        <li><Link to={`/league/${currentLeagueId}/teams`} className="block hover:text-gray-400" onClick={handleLinkClick}>Lag</Link></li>
                    </ul>
                </div>
            )}

            {/* Justera huvudinnehållets padding baserat på banner synlighet */}
            <div className={`${showBanner ? 'pt-12' : 'pt-0'}`}>
                {/* Här renderas sidans huvudinnehåll */}
            </div>
        </>
    );
};

export default NavBar;