import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../AuthContext';
import { useNavigate } from 'react-router-dom';
import { ChevronDown, ChevronUp, Plus, X } from 'lucide-react';
import axios from 'axios';

const CreateMatches = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [leagues, setLeagues] = useState([]);
    const [teams, setTeams] = useState({});
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [expandedLeague, setExpandedLeague] = useState(null);

    // New state for handling multiple matches
    const [matchForms, setMatchForms] = useState({});

    const getCsrfToken = () => {
        return 'din-csrf-token';
    };

    useEffect(() => {
        if (!user || !user.is_staff) {
            navigate('/');
        }

        axios.defaults.headers.post['X-CSRFToken'] = getCsrfToken();

        const fetchLeagues = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + '/api/leagues/', {
                    headers: {
                        'Authorization': `Token ${localStorage.getItem('authToken')}`
                    },
                });
                const activeLeagues = response.data.filter(league => league.active);
                setLeagues(activeLeagues);

                // Initialize matchForms for each league with new fields
                const initialMatchForms = {};
                activeLeagues.forEach(league => {
                    initialMatchForms[league.id] = {
                        group: 'A',
                        round_number: '',
                        date: '',
                        time: '',
                        matches: [{ team1: '', team2: '', track: '' }]
                    };
                });
                setMatchForms(initialMatchForms);
            } catch (error) {
                console.error('Error fetching leagues:', error);
                setError('Failed to fetch leagues');
            }
        };

        fetchLeagues();
    }, [user, navigate]);

    const loadTeams = async (leagueId) => {
        try {
            const group = matchForms[leagueId]?.group || 'A';
            const teamsResponse = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/teams/?group=${group}`);
            if (!teamsResponse.ok) throw new Error(`Failed to fetch teams for league ID ${leagueId}`);
            const teamsData = await teamsResponse.json();

            // Filtrera bort lag som saknar groupid eller sätt default värde
            const validTeams = teamsData.map(team => ({
                ...team,
                groupid: team.groupid || '-' // Sätt default värde om groupid är null
            }));

            setTeams(prevTeams => ({ ...prevTeams, [leagueId]: validTeams }));
        } catch (error) {
            console.error('Error fetching teams:', error);
            setError('Failed to fetch teams');
        }
    };

    const handleTeamClick = (leagueId) => {
        loadTeams(leagueId);
    };

    const handleFormChange = (leagueId, field, value) => {
        setMatchForms(prev => ({
            ...prev,
            [leagueId]: {
                ...prev[leagueId],
                [field]: value
            }
        }));

        if (field === 'group') {
            loadTeams(leagueId);
        }
    };

    const handleMatchChange = (leagueId, index, field, value) => {
        setMatchForms(prev => {
            const newMatches = [...prev[leagueId].matches];
            newMatches[index] = {
                ...newMatches[index],
                [field]: value
            };
            return {
                ...prev,
                [leagueId]: {
                    ...prev[leagueId],
                    matches: newMatches
                }
            };
        });
    };

    const addMatch = (leagueId) => {
        setMatchForms(prev => ({
            ...prev,
            [leagueId]: {
                ...prev[leagueId],
                matches: [...prev[leagueId].matches, { team1: '', team2: '', track: '' }]
            }
        }));
    };

    const removeMatch = (leagueId, index) => {
        setMatchForms(prev => ({
            ...prev,
            [leagueId]: {
                ...prev[leagueId],
                matches: prev[leagueId].matches.filter((_, i) => i !== index)
            }
        }));
    };

    const handleSubmit = async (e, leagueId) => {
        e.preventDefault();
        setIsSubmitting(true);
        setError(null);

        const form = matchForms[leagueId];

        // Validation
        if (!form.group || !form.date || !form.time || !form.round_number) {
            setError('Please fill in all common fields');
            setIsSubmitting(false);
            return;
        }

        // Validate all matches
        const invalidMatches = form.matches.some(match =>
            !match.team1 || !match.team2 || match.team1 === match.team2 || !match.track
        );

        if (invalidMatches) {
            setError('Please check all matches - teams must be selected, cannot be the same, and track must be specified');
            setIsSubmitting(false);
            return;
        }

        try {
            // Create all matches in sequence
            for (const match of form.matches) {
                const matchData = {
                    team1: match.team1,
                    team2: match.team2,
                    track: match.track,
                    date: `${form.date}T${form.time}`, // Combine date and time
                    round_number: form.round_number,
                    group: form.group,
                    league: leagueId
                };

                await axios.post(process.env.REACT_APP_API_URL + '/api/matches/create/', matchData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': getCsrfToken(),
                        'Authorization': `Token ${localStorage.getItem('authToken')}`
                    },
                    withCredentials: true,
                });
            }

            // Reset form
            setMatchForms(prev => ({
                ...prev,
                [leagueId]: {
                    group: 'A',
                    round_number: '',
                    date: '',
                    time: '',
                    matches: [{ team1: '', team2: '', track: '' }]
                }
            }));

            setSuccessMessage('All matches created successfully');
            setError(null);
        } catch (error) {
            setError(error.response?.data?.message || 'Could not create matches');
        } finally {
            setIsSubmitting(false);
        }
    };

    const renderTeamOptions = (teams, leagueId) => {
        if (!teams || !teams[leagueId]) return null;

        const sortedTeams = [...teams[leagueId]].sort((a, b) => {
            const groupIdA = a.groupid || '-';
            const groupIdB = b.groupid || '-';

            // Separera bokstav och nummer
            const [letterA, numberA = '0'] = groupIdA.match(/([A-Za-z])?(\d+)?/).slice(1);
            const [letterB, numberB = '0'] = groupIdB.match(/([A-Za-z])?(\d+)?/).slice(1);

            // Jämför först bokstäverna
            if (letterA !== letterB) {
                return letterA?.localeCompare(letterB);
            }

            // Om bokstäverna är samma, jämför numren numeriskt
            return parseInt(numberA) - parseInt(numberB);
        });

        return sortedTeams.map((team) => (
            <option key={team.id} value={team.id}>
                {team.groupid || '-'} - {team.team.name}
            </option>
        ));
    };


    return (
        <div className="min-h-screen flex flex-col">
            {leagues.length === 0 ? (
                <p className="text-gray-500">No leagues available</p>
            ) : (
                leagues.map((league) => (
                    <div key={league.id} className="border rounded-lg shadow-sm">
                        <button
                            onClick={() => setExpandedLeague(expandedLeague === league.id ? null : league.id)}
                            className="w-full p-4 flex justify-between items-center bg-gray-50 hover:bg-gray-100 rounded-t-lg"
                        >
                            <h2 className="text-xl font-semibold">{league.name}</h2>
                            {expandedLeague === league.id ? <ChevronUp /> : <ChevronDown />}
                        </button>

                        {expandedLeague === league.id && (
                            <div className="p-4">
                                <form onSubmit={(e) => handleSubmit(e, league.id)} className="space-y-4">
                                    <div className="grid grid-cols-4 gap-4">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">Grupp</label>
                                            <select
                                                value={matchForms[league.id]?.group}
                                                onChange={(e) => handleFormChange(league.id, 'group', e.target.value)}
                                                className="mt-1 block w-full rounded-md border border-gray-300 p-2"
                                            >
                                                <option value="A">A</option>
                                                <option value="B">B</option>
                                            </select>
                                        </div>

                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">Omgång</label>
                                            <input
                                                type="number"
                                                value={matchForms[league.id]?.round_number}
                                                onChange={(e) => handleFormChange(league.id, 'round_number', e.target.value)}
                                                className="mt-1 block w-full rounded-md border border-gray-300 p-2"
                                            />
                                        </div>

                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">Datum</label>
                                            <input
                                                type="date"
                                                value={matchForms[league.id]?.date}
                                                onChange={(e) => handleFormChange(league.id, 'date', e.target.value)}
                                                className="mt-1 block w-full rounded-md border border-gray-300 p-2"
                                            />
                                        </div>

                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">Tid</label>
                                            <input
                                                type="time"
                                                value={matchForms[league.id]?.time}
                                                onChange={(e) => handleFormChange(league.id, 'time', e.target.value)}
                                                className="mt-1 block w-full rounded-md border border-gray-300 p-2"
                                            />
                                        </div>
                                    </div>

                                    <div className="space-y-4">
                                        {matchForms[league.id]?.matches.map((match, index) => (
                                            <div key={index} className="flex items-center space-x-4">
                                                <select
                                                    value={match.team1}
                                                    onChange={(e) => handleMatchChange(league.id, index, 'team1', e.target.value)}
                                                    onClick={() => handleTeamClick(league.id)}
                                                    className="flex-1 rounded-md border border-gray-300 p-2"
                                                >
                                                    <option value="">Välj lag 1</option>
                                                    {renderTeamOptions(teams, league.id)}
                                                </select>

                                                {/* Uppdatera team2 select */}
                                                <select
                                                    value={match.team2}
                                                    onChange={(e) => handleMatchChange(league.id, index, 'team2', e.target.value)}
                                                    onClick={() => handleTeamClick(league.id)}
                                                    className="flex-1 rounded-md border border-gray-300 p-2"
                                                >
                                                    <option value="">Välj lag 2</option>
                                                    {renderTeamOptions(teams, league.id)}
                                                </select>

                                                <input
                                                    type="number"
                                                    value={match.track}
                                                    onChange={(e) => handleMatchChange(league.id, index, 'track', e.target.value)}
                                                    placeholder="Bana"
                                                    className="w-24 rounded-md border border-gray-300 p-2"
                                                    min="1"
                                                />

                                                {index > 0 && (
                                                    <button
                                                        type="button"
                                                        onClick={() => removeMatch(league.id, index)}
                                                        className="p-2 text-red-600 hover:text-red-800"
                                                    >
                                                        <X size={20} />
                                                    </button>
                                                )}
                                            </div>
                                        ))}
                                    </div>

                                    <div className="flex justify-between">
                                        <button
                                            type="button"
                                            onClick={() => addMatch(league.id)}
                                            className="flex items-center space-x-2 text-blue-600 hover:text-blue-800"
                                        >
                                            <Plus size={20} />
                                            <span>Lägg till match i omgången</span>
                                        </button>

                                        <button
                                            type="submit"
                                            disabled={isSubmitting}
                                            className="bg-gray-600 text-white px-4 py-2 rounded-md hover:bg-gray-800 disabled:bg-gray-400"
                                        >
                                            {isSubmitting ? 'Creating...' : 'Skapa matcher'}
                                        </button>
                                    </div>
                                </form>

                                {successMessage && (
                                    <p className="mt-4 text-green-500">{successMessage}</p>
                                )}
                                {error && (
                                    <p className="mt-4 text-red-500">{error}</p>
                                )}
                            </div>
                        )}
                    </div>
                ))
            )}
        </div>
    );
};

export default CreateMatches;