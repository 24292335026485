import React, { useEffect, useState } from 'react';
import { Trophy, Users, Award, User } from 'lucide-react';
import { FinalPlacementsList } from './FinalPlacementList';
import { StatisticsCard } from './StatisticsCard';

const ArchivedLeagueDetails = ({ leagueId, leagueData }) => {
  const [statistics, setStatistics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [groupAData, setGroupAData] = useState([]);
  const [groupBData, setGroupBData] = useState([]);

  useEffect(() => {
    const fetchLeagueResults = async () => {
      try {
        setLoading(true);

        // Fetch results for Playoff A
        const responseA = await fetch(
          `${process.env.REACT_APP_API_URL}/api/league-results/?playoff_group=A&league_ids=${leagueId}`
        );

        // Fetch results for Playoff B
        const responseB = await fetch(
          `${process.env.REACT_APP_API_URL}/api/league-results/?playoff_group=B&league_ids=${leagueId}`
        );

        if (!responseA.ok || !responseB.ok) {
          throw new Error('Failed to fetch league results');
        }

        const playoffAResults = await responseA.json();
        const playoffBResults = await responseB.json();

        // Create results object
        const updatedStatistics = {
          ...leagueData.statistics,
          finalPlacements: playoffAResults[0]?.results || [],
          loserBracketPlacements: playoffBResults[0]?.results || [],
          mostWins: playoffAResults[0]?.results?.find(result => result.finalplacement === 1)?.team || null,
          highestScore: playoffAResults[0]?.results?.find(result => result.rank === 1)?.team || null
        };

        setStatistics(updatedStatistics);

      } catch (error) {
        console.error('Fel vid hämtning av resultat:', error);
        setError('Could not fetch league results');
      } finally {
        setLoading(false);
      }
    };

    const fetchPointsForGroup = async (group) => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/table/?group=${group}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return await response.json();
      } catch (error) {
        console.error(`Error fetching points for group ${group}:`, error);
        setError(error.message);
        return [];
      }
    };

    const fetchPoints = async () => {
      try {
        setLoading(true);
        const [groupA, groupB] = await Promise.all([
          fetchPointsForGroup('A'),
          fetchPointsForGroup('B')
        ]);

        setGroupAData(groupA);
        setGroupBData(groupB);

      } catch (error) {
        console.error('Error in fetchPoints:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPoints();
    fetchLeagueResults();
  }, [leagueId, leagueData]);

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-xl text-red-600">Error: {error}</div>
      </div>
    );
  }

  const winner = statistics?.finalPlacements.find((placement) => placement.finalplacement === 1);

  const findBestTeamOverallSeason = (groupAData, groupBData) => {
    // Lägg till en null/undefined-kontroll
    if (!groupAData || !groupBData) {
      return null;
    }

    const combinedTeams = [...groupAData, ...groupBData];

    // Sortera lagen baserat på poäng och stenskillnad
    const sortedTeams = combinedTeams.sort((a, b) => {
      // Se till att både a och b har de förväntade egenskaperna
      const aPoints = a?.points || 0;
      const bPoints = b?.points || 0;
      const aStone = a?.stone_difference || 0;
      const bStone = b?.stone_difference || 0;

      // Först sortera efter poäng (fallande ordning)
      if (bPoints !== aPoints) {
        return bPoints - aPoints;
      }

      // Om poängen är lika, sortera efter stenskillnad (fallande ordning)
      return bStone - aStone;
    });

    // Returnera det första laget (bästa laget)
    return sortedTeams[0] || null;
  };

  const bestTeamOverall = findBestTeamOverallSeason(groupAData, groupBData);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      {loading ? (
        <div className="min-h-screen flex items-center justify-center">
          <img
            src={require('../../images/curling.gif')}
            alt="Loading..."
            className="w-24 h-24"
          />
        </div>
      ) : (
        <main className="max-w-7xl mx-auto px-4 py-16 pt-16 space-y-16 flex-grow">
          <section className='pt-10'>
            <div className="max-w-6xl mx-auto bg-white rounded-xl shadow-xl">
              {/* Header */}
              <div className="bg-gray-800 rounded-t-xl p-6">
                <h1 className="text-3xl font-bold text-white mb-2">{leagueData.name}</h1>
                <p className="text-blue-100">{leagueData.organizer}</p>
              </div>

              <div className="p-4 sm:p-6 space-y-6 sm:space-y-8">
                {/* Overview Statistics */}
                <div className="grid grid-cols-2 lg:grid-cols-4 gap-3 sm:gap-4">
                  <StatisticsCard
                    icon={<Users className="w-5 h-5 sm:w-6 sm:h-6" />}
                    title="Deltagande lag"
                    value={leagueData.teamscount}
                  />
                  <StatisticsCard
                    icon={<User className="w-5 h-5 sm:w-6 sm:h-6" />}
                    title="Deltagare"
                    value={leagueData.participants}
                  />
                  <StatisticsCard
                    icon={<Trophy className="w-5 h-5 sm:w-6 sm:h-6" />}
                    title="Matcher spelade"
                    value={leagueData.gamesplayed}
                  />
                  <StatisticsCard
                    icon={<Award className="w-5 h-5 sm:w-6 sm:h-6" />}
                    title="MVP"
                    value={leagueData.mvp}
                  />
                </div>

                {/* Vinnare */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="bg-gradient-to-br from-yellow-100 to-yellow-200 rounded-lg p-6">
                    <h3 className="text-lg font-semibold mb-4">Vinnare A-Slutspel</h3>
                    <div className="flex items-center space-x-4">
                      <Trophy className="w-8 h-8 text-yellow-600" />
                      <div>
                        <p className="font-bold text-lg">
                          {winner?.team.name || "Ingen data"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gradient-to-br from-blue-100 to-blue-200 rounded-lg p-6">
                    <h3 className="text-lg font-semibold mb-4">Bästa lag grundserien</h3>
                    <div className="flex items-center space-x-4">
                      <Award className="w-8 h-8 text-blue-600" />
                      <div>
                        <p className="font-bold text-lg">
                          {bestTeamOverall && bestTeamOverall.name
                            ? bestTeamOverall.name
                            : "Ingen data"}
                        </p>
                        <p className="text-sm text-gray-600">
                          {bestTeamOverall && bestTeamOverall.points
                            ? `${bestTeamOverall.points} poäng  |  ${bestTeamOverall.stone_difference} stenskillnad`
                            : "0 poäng"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Finalplacement table */}
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                  <div>
                    <FinalPlacementsList
                      title="A-Slutspel"
                      finalPlacements={statistics?.finalPlacements || []}
                    />
                  </div>

                  {statistics?.loserBracketPlacements && statistics.loserBracketPlacements.length > 0 && (
                    <div>
                      <FinalPlacementsList
                        title="B-Slutspel"
                        finalPlacements={statistics.loserBracketPlacements}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </main>
      )}
      <footer className="bg-gray-800 text-white text-center p-4 w-full mt-auto">
        <p>&copy; 2024 SweepScore</p>
      </footer>
    </div>
  );
};

export default ArchivedLeagueDetails;