import React, { useEffect } from 'react';
import { useAuth } from '../../../AuthContext';
import { useNavigate } from 'react-router-dom';


const Overview = ({ setActiveTab, setActiveSubTab }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    // Redirect if user is not logged in or has no admin rights
    if (!user || (!user.is_staff && !user.permissions?.is_league_admin)) {
      navigate('/');
    }
  }, [user, navigate]);  

  const getNavigationLinks = () => {
    if (user?.is_staff) {
      return [
        // Matcher (Matches) Tab
        {
          tab: 'matches',
          tabLabel: 'Matcher',
          subTabs: [
            { subTab: 'MatchResults', text: 'Rapportera matchresultat' },
            { subTab: 'PlayoffMatches', text: 'Rapportera slutspelsresultat' },
            { subTab: 'CreateMatches', text: 'Skapa matcher' }
          ]
        },
        // Leagues Tab
        {
          tab: 'leagues',
          tabLabel: 'Tävlingar',
          subTabs: [
            { subTab: 'CreateLeague', text: 'Skapa tävling' },
            { subTab: 'ActiveLeagues', text: 'Aktiva tävlingar' },
            { subTab: 'ArchivedLeagues', text: 'Arkiverade tävlingar' }
          ]
        },
        // Teams Tab
        {
          tab: 'teams',
          tabLabel: 'Lag',
          subTabs: [
            { subTab: 'CreateNewTeams', text: 'Skapa nytt lag' },
            { subTab: 'AllTeams', text: 'Hantera lag' },
            { subTab: 'ActiveLeagues', text: 'Lägg till lag i tävling' }
          ]
        },
        // Users Tab
        {
          tab: 'users',
          tabLabel: 'Användare',
          subTabs: [
            { subTab: 'CreateNewUser', text: 'Skapa ny användare' },
            { subTab: 'ManageUsers', text: 'Hantera användare' }
          ]
        }
      ];
    } else {
      // League Admin links
      return [
        {
          tab: 'matches',
          tabLabel: 'Matcher',
          subTabs: [
            { subTab: 'MatchResults', text: 'Rapportera matchresultat' },
            { subTab: 'PlayoffMatches', text: 'Rapportera slutspelsresultat' },
          ]
        }
      ];
    }
  };

  const navigationLinks = getNavigationLinks();

  const handleNavigation = (tab, subTab) => {
    // Update the tab and subtab state in the parent AdminPage component
    setActiveTab(tab);
    setActiveSubTab(subTab);
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-xl font-bold mb-4">Översikt</h2>

      <div className="grid md:grid-cols-2 gap-6">
        {navigationLinks.map((section) => (
          <div key={section.tab} className="bg-gray-50 rounded-lg p-4">
            <h3 className="text-lg font-semibold mb-3 text-gray-800">
              {section.tabLabel}
            </h3>
            <div className="space-y-2">
              {section.subTabs.map((link) => (
                <button
                  key={link.subTab}
                  onClick={() => handleNavigation(section.tab, link.subTab)}
                  className="block w-full text-left text-blue-600 hover:text-blue-800 hover:underline transition-colors"
                >
                  {link.text}
                </button>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Overview;