import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Users, Calendar, ChevronDown, ChevronUp, TrendingUp } from 'lucide-react';
import Footer from '../components/Footer';

const Teams = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { leagueId } = useParams();
    const [leagueName, setLeagueName] = useState('');
    const [groupATeams, setGroupATeams] = useState([]);
    const [groupBTeams, setGroupBTeams] = useState([]);
    const [teamMatches, setTeamMatches] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const pathParts = location.pathname.split('/');
        const teamId = pathParts[pathParts.length - 1];

        // If we're on a specific team URL, redirect to the teams page with search param
        if (pathParts.length > 4 && !isNaN(teamId)) {
            navigate(`/league/${leagueId}/teams?expandedTeam=${teamId}`, { replace: true });
        }
    }, [location, leagueId, navigate]);

    useEffect(() => {
        const fetchLeagueName = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/`);
                if (!response.ok) throw new Error('Failed to fetch league name');
                const data = await response.json();
                setLeagueName(data.name);
            } catch (error) {
                console.error('Error fetching league name:', error);
            }
        };

        const fetchTeamsInGroup = async (group) => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/teams/?group=${group}`);
                if (!response.ok) throw new Error(`Failed to fetch teams for group ${group}`);
                const data = await response.json();
                // Sortera data innan vi returnerar det
                return data.sort((a, b) => {
                    const nameA = a.team?.name || '';
                    const nameB = b.team?.name || '';
                    return nameA.localeCompare(nameB);
                });
            } catch (error) {
                console.error(`Error fetching teams for group ${group}:`, error);
                return [];
            }
        };

        const fetchMatches = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/matches/`);
                if (!response.ok) throw new Error('Failed to fetch matches');
                const data = await response.json();

                const matchesByTeam = {};
                data.forEach(match => {
                    if (!matchesByTeam[match.team1_name]) matchesByTeam[match.team1_name] = [];
                    if (!matchesByTeam[match.team2_name]) matchesByTeam[match.team2_name] = [];

                    const matchData = {
                        id: match.id,
                        date: match.date,
                        home_team: { name: match.team1_name },
                        away_team: { name: match.team2_name },
                        home_score: match.team1_score,
                        away_score: match.team2_score,
                        status: match.team1_score !== null ? 'completed' : 'scheduled',
                        round_number: match.round_number
                    };

                    matchesByTeam[match.team1_name].push(matchData);
                    matchesByTeam[match.team2_name].push(matchData);
                });

                setTeamMatches(matchesByTeam);
            } catch (error) {
                console.error('Error fetching matches:', error);
            }
        };

        const fetchAllData = async () => {
            try {
                setLoading(true);
                await fetchLeagueName();
                const [groupA, groupB] = await Promise.all([
                    fetchTeamsInGroup('A'),
                    fetchTeamsInGroup('B')
                ]);
                setGroupATeams(groupA);
                setGroupBTeams(groupB);
                await fetchMatches();
            } catch (error) {
                console.error('Error fetching all data:', error);
            } finally {
                setLoading(false);
            }
        };

        if (leagueId) {
            fetchAllData();
        }
    }, [leagueId]);

    const calculateTeamForm = (matches, teamName) => {
        if (!matches) return [];

        // Filter out unplayed matches (where scores are 0-0 or null)
        const playedMatches = matches.filter(match =>
            match.status === 'completed' &&
            (match.home_score !== 0 || match.away_score !== 0) &&
            match.home_score !== null &&
            match.away_score !== null
        );

        // Sort matches by date, most recent first
        const sortedMatches = playedMatches.sort((a, b) =>
            new Date(b.date) - new Date(a.date)
        );

        // Take only the 5 most recent matches
        const recentMatches = sortedMatches.slice(0, 5);

        // Calculate form for each match
        return recentMatches.map(match => {
            const isHomeTeam = match.home_team.name === teamName;
            const teamScore = isHomeTeam ? match.home_score : match.away_score;
            const opponentScore = isHomeTeam ? match.away_score : match.home_score;

            if (teamScore > opponentScore) return 'W';
            if (teamScore < opponentScore) return 'L';
            return 'D';
        }).reverse();
    };

    const getFormColor = (result) => {
        switch (result) {
            case 'W': return 'bg-green-500';
            case 'L': return 'bg-red-500';
            case 'D': return 'bg-yellow-500';
            default: return 'bg-gray-300';
        }
    };

    const TeamCard = ({ team }) => {

        const [activeTab, setActiveTab] = useState('members');
        const teamForm = calculateTeamForm(teamMatches[team.team.name], team.team.name);
        const searchParams = new URLSearchParams(location.search);
        const expandedTeamId = searchParams.get('expandedTeam');
        const isExpanded = expandedTeamId === team.team.id.toString();
        const teamRef = useRef(null);
        const [isMounted, setIsMounted] = useState(false);

        useEffect(() => {
            setIsMounted(true);
            return () => setIsMounted(false);
        }, []);

        useEffect(() => {
        if (isExpanded && isMounted && teamRef.current) {
            const scrollToTeam = () => {
                try {
                    teamRef.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    });
                } catch (error) {
                    console.error('Scroll error:', error);
                }
            };

            // Längre timeout för att säkerställa att DOM är redo
            const timeoutId = setTimeout(scrollToTeam, 300);
            return () => clearTimeout(timeoutId);
        }
    }, [isExpanded, isMounted]);

        const toggleExpand = () => {
            // Update URL when expanding/collapsing
            if (!isExpanded) {
                // Add team ID to URL when expanding
                searchParams.set('expandedTeam', team.team.id);
                navigate(`/league/${leagueId}/teams?${searchParams.toString()}`);
            } else {
                // Remove team ID from URL when collapsing
                searchParams.delete('expandedTeam');
                navigate(`/league/${leagueId}/teams?${searchParams.toString()}`);
            }
        };

        const MatchCard = ({ match, teamName }) => (
            <div className="p-3 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors">
                {/* Desktop layout (dold på mobil) */}
                <div className="hidden md:flex items-center w-full">
                    <div className="flex-1 text-right">
                        <span className={`text-sm truncate block ${match.home_team.name === teamName ? 'font-medium' : ''}`}>
                            {match.home_team.name}
                        </span>
                    </div>

                    <div className="flex flex-col items-center justify-center mx-8 min-w-[120px]">
                        <div className="flex items-center space-x-3">
                            <span className="text-lg font-semibold">
                                {match.status === 'completed' ? match.home_score : '-'}
                            </span>
                            <span className="text-gray-400 font-medium text-sm">vs</span>
                            <span className="text-lg font-semibold">
                                {match.status === 'completed' ? match.away_score : '-'}
                            </span>
                        </div>
                    </div>

                    <div className="flex-1 text-left">
                        <span className={`text-sm truncate block ${match.away_team.name === teamName ? 'font-medium' : ''}`}>
                            {match.away_team.name}
                        </span>
                    </div>
                </div>

                {/* Mobile layout (hidden on desktop) */}
                <div className="md:hidden flex flex-col items-center space-y-2">
                    {/* Home team on top */}
                    <span className={`text-sm text-center ${match.home_team.name === teamName ? 'font-medium' : ''}`}>
                        {match.home_team.name}
                    </span>

                    {/* Score and date in middle */}
                    <div className="flex flex-col items-center">
                        <span className="font-bold text-lg">
                            {match.status === 'completed'
                                ? `${match.home_score} - ${match.away_score}`
                                : 'vs'}
                        </span>
                    </div>

                    {/* Away team at bottom */}
                    <span className={`text-sm text-center ${match.away_team.name === teamName ? 'font-medium' : ''}`}>
                        {match.away_team.name}
                    </span>
                    <span className="text-xs text-gray-500">
                        {new Date(match.date).toLocaleDateString()}
                    </span>
                </div>

                {/* Date for desktop layout */}
                <div className="hidden md:block text-center text-xs text-gray-500 mt-1">
                    {new Date(match.date).toLocaleDateString()}
                </div>
            </div>
        );

        if (loading) {
            return (
                <div className="min-h-screen flex items-center justify-center">
                    <img
                        src={require('../images/curling.gif')}
                        alt="Loading..."
                        className="w-24 h-24"
                    />
                </div>
            );
        }

        return (
            <div ref={teamRef} className="bg-white rounded-lg shadow-md overflow-hidden">
            <button
                    onClick={toggleExpand}
                    className="w-full p-4 flex items-center justify-between hover:bg-gray-50 transition-colors"
                >
                    <h3 className="text-xl font-bold">{team.team.name}</h3>
                    {isExpanded ? (
                        <ChevronUp className="w-5 h-5 text-gray-500" />
                    ) : (
                        <ChevronDown className="w-5 h-5 text-gray-500" />
                    )}
                </button>

                {isExpanded && (
                    <div className="p-6 border-t border-gray-100">
                        {/* Custom Tabs */}
                        <div className="mb-4">
                            <div className="flex border-b border-gray-200">
                                <button
                                    onClick={() => setActiveTab('members')}
                                    className={`flex items-center gap-2 px-4 py-2 font-medium text-sm ${activeTab === 'members'
                                        ? 'border-b-2 border-blue-500 text-blue-600'
                                        : 'text-gray-500 hover:text-gray-700'
                                        }`}
                                >
                                    <Users className="w-4 h-4" />
                                    Medlemmar
                                </button>
                                <button
                                    onClick={() => setActiveTab('matches')}
                                    className={`flex items-center gap-2 px-4 py-2 font-medium text-sm ${activeTab === 'matches'
                                        ? 'border-b-2 border-blue-500 text-blue-600'
                                        : 'text-gray-500 hover:text-gray-700'
                                        }`}
                                >
                                    <Calendar className="w-4 h-4" />
                                    Matcher
                                </button>
                            </div>
                        </div>

                        {/* Tab Content */}
                        <div className="mt-4">
                            {activeTab === 'members' && (
                                <div className="space-y-2">
                                    {team.team.members?.map(member => (
                                        <div
                                            key={member.id}
                                            className="flex items-center justify-between p-2 bg-gray-50 rounded hover:bg-gray-100 transition-colors"
                                        >
                                            <span className="font-medium">
                                                {member.first_name} {member.last_name}
                                            </span>
                                            <span className="text-sm text-gray-500">{member.role}</span>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {activeTab === 'matches' && (
                                <div className="space-y-4">
                                    {/* Form Display */}
                                    <div className="flex items-center gap-2 mb-4 bg-gray-50 p-3 rounded-lg">
                                        <TrendingUp className="w-4 h-4 text-gray-600" />
                                        <span className="font-semibold">Form:</span>
                                        <div className="flex gap-1">
                                            {teamForm.map((result, index) => (
                                                <span
                                                    key={index}
                                                    className={`w-6 h-6 ${getFormColor(result)} rounded-full flex items-center justify-center text-white text-sm font-bold`}
                                                >
                                                    {result}
                                                </span>
                                            ))}
                                        </div>
                                    </div>

                                    {/* Recent Matches */}
                                    <div className="space-y-2">
                                        {teamMatches[team.team.name]?.slice(0, 10).map(match => (
                                            <MatchCard
                                                key={match.id}
                                                match={match}
                                                teamName={team.team.name}
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const renderGroup = (teams, groupName) => {
        if (!teams || teams.length === 0) return null;

        return (
            <div className="mb-8 w-full">
                <h3 className="text-xl font-bold mb-4 text-gray-800">Grupp {groupName}</h3>
                <div className="grid grid-cols-1 gap-2">
                    {teams.map(team => (
                        <TeamCard key={team.id} team={team} />
                    ))}
                </div>
            </div>
        );
    };

    if (loading) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <img
                    src={require('../images/curling.gif')}
                    alt="Loading..."
                    className="w-24 h-24"
                />
            </div>
        );
    }

    const hasNoTeams = (!groupATeams || groupATeams.length === 0) &&
        (!groupBTeams || groupBTeams.length === 0);

    if (hasNoTeams) {
        return (
            <div className="min-h-screen bg-gray-100 flex flex-col items-center pt-16">
                <main className="flex-grow container mx-auto px-4 py-8">
                    <h2 className="text-2xl font-bold mb-6 text-gray-800">{leagueName}</h2>
                    <div className="bg-white rounded-lg shadow-md p-8 text-center">
                        <p className="text-gray-600">Inga lag är kopplade till tävlingen</p>
                    </div>
                </main>
                <footer className="bg-gray-800 text-white text-center p-4 w-full mt-auto">
                    <p>&copy; 2024 SweepScore</p>
                </footer>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col items-center pt-16">
            <main className="flex-grow container mx-auto px-4 py-8">
                <h2 className="text-2xl font-bold mb-6 text-gray-800">{leagueName}</h2>
                {renderGroup(groupATeams, 'A')}
                {renderGroup(groupBTeams, 'B')}
            </main>
            <Footer />
        </div>
    );
};

export default Teams;