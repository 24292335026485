import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../AuthContext';
import { useNavigate } from 'react-router-dom';
import MatchResults from './MatchResults';
import CreateMatches from './CreateMatches';
import PlayoffMatches from './PlayoffMatches';
import SubTabButton from '../Common/SubTabButton';

const MatchesTab = ({ activeSubTab }) => {
  const [playoffDataFetched, setPlayoffDataFetched] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Kontrollera behörigheter
    if (!user || (!user.is_staff && !user.permissions?.is_league_admin)) {
      navigate('/');
      return;
    }

    if (activeSubTab === 'PlayoffMatches') {
      setPlayoffDataFetched(true);
    }
  }, [activeSubTab, user, navigate]);

  const openSubTab = (event, tabName) => {
    const tabcontent = document.getElementsByClassName("tabcontent");
    for (let i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    const tablinks = document.getElementsByClassName("tablinks");
    for (let i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }

    document.getElementById(tabName).style.display = "block";
    event.currentTarget.className += " active";

    if (tabName === 'PlayoffMatches') {
      setPlayoffDataFetched(true);
    }
  };

  // Bestäm vilka flikar som ska visas baserat på användarens behörigheter
  const getAvailableTabs = () => {
    const tabs = [];
    
    // Alla admin-typer kan se matchresultat
    tabs.push({
      id: 'MatchResults',
      label: 'Matchresultat',
    });

    // Alla admin-typer kan se slutspelsresultat
    tabs.push({
      id: 'PlayoffMatches',
      label: 'Slutspelsresultat',
    });

    // Endast staff users kan skapa matcher
    if (user?.is_staff) {
      tabs.push({
        id: 'CreateMatches',
        label: 'Skapa matcher',
      });
    }

    return tabs;
  };

  const availableTabs = getAvailableTabs();

  return (
    <div>
      <div className="subtab">
        {availableTabs.map(tab => (
          <SubTabButton 
            key={tab.id}
            onClick={(e) => openSubTab(e, tab.id)} 
            label={tab.label} 
          />
        ))}
      </div>

      <div id="MatchResults" className="tabcontent" style={{ display: activeSubTab === 'MatchResults' ? 'block' : 'none' }}>
        <MatchResults />
      </div>

      <div id="PlayoffMatches" className="tabcontent" style={{ display: activeSubTab === 'PlayoffMatches' ? 'block' : 'none' }}>
        {playoffDataFetched ? <PlayoffMatches /> : null}
      </div>

      {user?.is_staff && (
        <div id="CreateMatches" className="tabcontent" style={{ display: activeSubTab === 'CreateMatches' ? 'block' : 'none' }}>
          <CreateMatches />
        </div>
      )}
    </div>
  );
};

export default MatchesTab;