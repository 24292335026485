import React from 'react';
import { Medal } from 'lucide-react';

export function FinalPlacementsList({ finalPlacements = [], title }) {
  const getMedalColor = (rank) => {
    switch (rank) {
      case 1: return 'text-yellow-500';  // Guld
      case 2: return 'text-gray-400';    // Silver
      case 3: return 'text-amber-600';   // Brons
      default: return 'text-gray-400';   // Ingen medalj
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-4 md:p-6">
      <h3 className="text-xl font-bold mb-4">{title}</h3>
      <div className="space-y-3">
        {finalPlacements.slice(0, 20).map((placement, index) => {
          // Använd finalplacement för ranking om rank inte finns
          const rank = placement.rank || placement.finalplacement || (index + 1);
          
          // Ensure team is a string, use index as fallback key
          const teamName = placement.team?.name || placement.team || `Team ${index + 1}`;
          
          return (
            <div
              key={`${teamName}-${rank}`}
              className="flex items-center p-3 rounded-lg hover:bg-gray-50 transition-colors"
            >
              <div className="w-8 text-center font-bold">
                {rank <= 3 ? (
                  <Medal className={`w-6 h-6 ${getMedalColor(rank)}`} />
                ) : (
                  <span className="text-gray-500">{rank}</span>
                )}
              </div>
              <div className="ml-4 flex-grow">
                <p className="font-semibold text-gray-900">{teamName}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}