import React, { useState } from 'react';
import axios from 'axios';

const CreateTeam = () => {
  const [newTeam, setNewTeam] = useState({ name: '' });
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  
  const handleTeamInputChange = (e) => {
    const { name, value } = e.target;
    setNewTeam(prevTeam => ({
      ...prevTeam,
      [name]: value,
    }));
  };

  const handleTeamSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        process.env.REACT_APP_API_URL + '/api/new-team/',
        newTeam,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${localStorage.getItem('authToken')}`
          },
          withCredentials: true,
        }
      );

      setSuccessMessage(`New team created: ${newTeam.name}`);
      setNewTeam({ name: '' });
      setError(null);
      
    } catch (error) {
      setError(error.response?.data?.error || 'Error creating team');
    }
  };


  return (
    <div className="space-y-6">    
      <div>
        <h3 className="text-lg font-semibold">Skapa nytt lag</h3>
        <form onSubmit={handleTeamSubmit} className="bg-gray-100 p-4 rounded-lg shadow-md">
          <input
            type="text"
            name="name"
            placeholder="Lagnamn"
            value={newTeam.name}
            onChange={handleTeamInputChange}
            required
            className="border p-2 rounded w-full mt-2"
          />
          <button type="submit" className="bg-gray-600 text-white px-4 py-1 mt-4 rounded-lg hover:bg-gray-800">
            Skapa lag
          </button>
        </form>
      </div>

      {successMessage && (
        <p className="text-green-500">{successMessage}</p>
      )}
      {error && (
        <p className="text-red-500">{error}</p>
      )}
    </div>
  );
};

export default CreateTeam;