import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { User, Users, Calendar, ListOrdered, Trophy, UsersRound, CalendarClock } from 'lucide-react';
import ArchivedLeagueDetails from '../components/archivedleagues/ArchivedLeagueDetails';
import Footer from '../components/Footer';

const LeagueDetail = () => {
  const { id } = useParams();
  const [league, setLeague] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + `/api/leagues/${id}/`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch league data');
        }
        return response.json();
      })
      .then(data => {
        setLeague(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching league:', error);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <img
          src={require('../images/curling.gif')}
          alt="Loading..."
          className="w-24 h-24"
        />
      </div>
    );
  }

  if (!league) {
    return <div className="flex justify-center items-center min-h-screen">League not found</div>;
  }

  if (!league.active) {
    return <ArchivedLeagueDetails leagueId={id} leagueData={league} />;
  }

  const navigationLinks = [
    { to: `/league/${id}/matches`, text: 'Matcher', icon: Calendar },
    { to: `/league/${id}/table`, text: 'Tabell', icon: ListOrdered },
    { to: `/league/${id}/playoff`, text: 'Slutspel', icon: Trophy },
    { to: `/league/${id}/teams`, text: 'Lag', icon: UsersRound }
  ];

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center pt-16">
      <main className="w-full max-w-7xl mx-auto px-4 py-16 pt-16 space-y-16 flex-grow flex justify-center">
      <div className="w-full md:w-2/3 lg:w-2/4 bg-white rounded-xl overflow-hidden shadow-lg transition-all duration-300 hover:shadow-xl">
          <div className="bg-gray-800 px-6 py-4">
            <h2 className="text-xl font-bold text-white">{league.name}</h2>
          </div>

          <div className="p-6 space-y-4">
            <div className="flex items-start space-x-3">
              <User className="w-5 h-5 text-gray-900 mt-0.5 flex-shrink-0" />
              <div>
                <p className="text-sm text-gray-500">Arrangör</p>
                <p className="text-gray-900 font-medium">{league.organizer}</p>
              </div>
            </div>

            <div
              className="flex items-start space-x-3 cursor-pointer hover:bg-gray-50 rounded-lg"
              onClick={() => navigate(`/league/${id}/schedule`)}
            >
              <CalendarClock className="w-5 h-5 text-gray-900 mt-0.5 flex-shrink-0" />
              <div>
                <p className="text-sm text-gray-500">Spelschema</p>
                <p className="text-blue-600 ">Visa</p>
              </div>
            </div>

            {league.reserve_players && league.reserve_players.length > 0 && (
              <div
                className="flex items-start space-x-3 cursor-pointer hover:bg-gray-50 rounded-lg"
                onClick={() => setShowModal(true)}
              >
                <Users className="w-5 h-5 text-gray-900 mt-0.5 flex-shrink-0" />
                <div>
                  <p className="text-sm text-gray-500">Reservspelare</p>
                  <p className="text-blue-600 ">Öppna listan</p>
                </div>
              </div>
            )}

            {/* Navigation Links - Always visible */}
            <div className="pt-4 border-t border-gray-200">
              <div className="grid gap-3">
                {navigationLinks.map((link) => (
                  <Link
                    key={link.to}
                    to={link.to}
                    className="flex items-center justify-between px-4 py-3 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
                  >
                    <div className="flex items-center space-x-3">
                      <link.icon className="w-5 h-5 text-gray-600" />
                      <span className="font-medium text-gray-900">{link.text}</span>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">Reservspelare</h3>
              <button
                onClick={() => setShowModal(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                ✕
              </button>
            </div>
            <div className="space-y-3">
              {league.reserve_players.map((player) => (
                <div
                  key={player.id}
                  className="flex justify-between items-center py-2 px-4 bg-gray-50 rounded-lg"
                >
                  <span className="font-medium">
                    {player.first_name} {player.last_name}
                  </span>
                  <span className="text-gray-600">{player.phone_number}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default LeagueDetail;