import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { User, Mail, Save, Search } from 'lucide-react';

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + '/api/users/', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${localStorage.getItem('authToken')}`
        }
      });
      setUsers(response.data || []);
    } catch (error) {
      setError('Error fetching users');
      setUsers([]);
    }
  };

  const handleSaveUser = async (userToSave) => {
    try {  
      await axios.put(process.env.REACT_APP_API_URL + `/api/users/admin/update/${userToSave.id}/`, userToSave, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${localStorage.getItem('authToken')}`
        }
      });
      
      setEditingUser(null);
      setSuccessMessage('User updated successfully');
      fetchUsers();
    } catch (error) {
      if (error.response?.data?.username) {
        setError('Username already exists');
      } else if (error.response?.data?.email) {
        setError('Email already exists');
      } else {
        setError('Error updating user');
      }
    }
  };

  const filteredUsers = users.filter(user => {
    if (!user) return false;
    
    const username = user.username?.toLowerCase() || '';
    const email = user.email?.toLowerCase() || '';
    const search = searchQuery.toLowerCase();
    
    return username.includes(search) || email.includes(search);
  });

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 gap-4">
        <h2 className="text-2xl font-bold">Manage Users</h2>
        <div className="relative w-full sm:w-64">
          <input
            type="text"
            placeholder="Search users..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
        </div>
      </div>

      <div className="grid grid-cols-1 gap-4">
        {filteredUsers.map((userItem) => (
          userItem && (
            <div 
              key={userItem.id} 
              className="bg-white shadow-md rounded-lg p-4 transition-all hover:shadow-lg"
            >
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
                <div className="flex-1">
                  <div className="flex items-center mb-3">
                    <User size={24} className="mr-3 flex-shrink-0 text-gray-600" />
                    {editingUser?.id === userItem.id ? (
                      <input
                        type="text"
                        value={editingUser.username || ''}
                        onChange={(e) =>
                          setEditingUser({ ...editingUser, username: e.target.value })
                        }
                        className="px-2 py-1 border rounded w-full"
                      />
                    ) : (
                      <h3 className="text-lg font-medium">{userItem.username}</h3>
                    )}
                  </div>
                  <div className="flex items-center">
                    <Mail size={24} className="mr-3 flex-shrink-0 text-gray-600" />
                    {editingUser?.id === userItem.id ? (
                      <input
                        type="email"
                        value={editingUser.email || ''}
                        onChange={(e) =>
                          setEditingUser({ ...editingUser, email: e.target.value })
                        }
                        className="px-2 py-1 border rounded w-full"
                      />
                    ) : (
                      <p className="text-gray-500">{userItem.email}</p>
                    )}
                  </div>
                </div>
                
                <div className="flex justify-end">
                  {editingUser?.id === userItem.id ? (
                    <div className="flex gap-2">
                      <button
                        onClick={() => handleSaveUser(editingUser)}
                        className="inline-flex items-center bg-gray-600 hover:bg-gray-800 text-white font-medium py-2 px-4 rounded-lg transition-colors"
                      >
                        <Save size={18} className="mr-2" />
                        Save
                      </button>
                      <button
                        onClick={() => setEditingUser(null)}
                        className="inline-flex items-center bg-gray-400 hover:bg-gray-600 text-white font-medium py-2 px-4 rounded-lg transition-colors"
                      >
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <button
                      onClick={() => setEditingUser(userItem)}
                      className="inline-flex items-center bg-gray-600 hover:bg-gray-800 text-white font-medium py-2 px-4 rounded-lg transition-colors"
                    >
                      <User size={18} className="mr-2" />
                      Edit
                    </button>
                  )}
                </div>
              </div>
            </div>
          )
        ))}
      </div>

      {successMessage && (
        <div className="mt-4 p-3 bg-green-100 text-green-700 rounded-lg">{successMessage}</div>
      )}
      {error && (
        <div className="mt-4 p-3 bg-red-100 text-red-700 rounded-lg">{error}</div>
      )}
    </div>
  );
};

export default ManageUsers;