import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import axios from 'axios';
import Footer from '../components/Footer';

const MyCompetitions = () => {
    const { user } = useAuth();
    const [competitions, setCompetitions] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCompetitions = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + '/api/mycompetitions/', {
                    headers: {
                        Authorization: `Token ${localStorage.getItem('authToken')}`,
                    },
                });
                setCompetitions(response.data);  // Corrected to set competitions data
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    setError('Du är inte medlem i något lag.');
                } else {
                    setError('Kunde inte ladda tävlingsdata');
                }
            }
        };

        if (user) {
            fetchCompetitions();
        }
    }, [user]);

    if (error) {
        return <div className="text-red-500 text-center mt-4">{error}</div>;
    }

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col items-center pt-16">
            <main className="flex-grow container mx-auto px-4 py-8">
                <h2 className="text-2xl font-bold mb-6">Mina Tävlingar</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {competitions.length === 0 ? (
                        <p>Inga tävlingar tillgängliga.</p>
                    ) : (
                        competitions
                            .sort((a, b) => {
                                // Sortera så att aktiva kommer först
                                if (a.active && !b.active) return -1;
                                if (!a.active && b.active) return 1;
                                return 0;
                            })
                            .slice(0, 5)
                            .map(comp => (
                                <div key={comp.id} className="league-card bg-white shadow-md rounded-lg p-4 hover:shadow-lg transition-shadow">
                                    <h3 className="text-xl font-semibold mb-2">{comp.name}</h3>
                                    <Link to={`/league/${comp.id}`} className="text-blue-500 hover:text-blue-700">
                                        Gå till tävlingen
                                    </Link>
                                    <div className="flex space-x-3 mt-4">
                                        {comp.active ? (
                                            <span className="inline-block px-2 py-1 text-sm bg-green-100 text-green-800 rounded">
                                                Aktiv
                                            </span>
                                        ) : (
                                            <span className="inline-block px-2 py-1 text-sm bg-red-100 text-red-800 rounded">
                                                Avslutad
                                            </span>
                                        )}
                                    </div>
                                </div>
                            ))
                    )}
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default MyCompetitions;