import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const PlayoffMatches = () => {
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [successMessages, setSuccessMessages] = useState({});
  const [groupATeams, setGroupATeams] = useState({});
  const [groupBTeams, setGroupBTeams] = useState({});
  const [leagues, setLeagues] = useState([]);
  const [selectedLeague, setSelectedLeague] = useState(null);

  const getCsrfToken = useCallback(() => {
    return 'din-csrf-token';
  }, []);

  const fetchTeamsInGroup = useCallback(async (leagueId, group) => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/teams/?group=${group}`, {
        headers: {
          'Authorization': `Token ${localStorage.getItem('authToken')}`
        }
      });
      return response.data;
    } catch (error) {
      console.error(`Error fetching teams in group ${group} for league ${leagueId}:`, error);
      throw error;
    }
  }, []);

  const fetchTeamsForAllLeagues = useCallback(async () => {
    const groupAResults = {};
    const groupBResults = {};

    for (const league of leagues) {
      try {
        const [groupA, groupB] = await Promise.all([
          fetchTeamsInGroup(league.id, 'A'),
          fetchTeamsInGroup(league.id, 'B')
        ]);
        groupAResults[league.id] = groupA;
        groupBResults[league.id] = groupB;
      } catch (error) {
        console.error(`Error fetching teams for league ${league.name}:`, error);
        groupAResults[league.id] = [];
        groupBResults[league.id] = [];
      }
    }

    setGroupATeams(groupAResults);
    setGroupBTeams(groupBResults);
  }, [leagues, fetchTeamsInGroup]);

  const fetchPlayoffMatches = useCallback(async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + '/api/playoffmatches/', {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': getCsrfToken(),
          'Authorization': `Token ${localStorage.getItem('authToken')}`
        },
        withCredentials: true,
      });
      setMatches(response.data);
      await fetchTeamsForAllLeagues();
    } catch (error) {
      console.error("There was an error fetching the playoff matches!", error);
    }
  }, [getCsrfToken, fetchTeamsForAllLeagues]);

  const fetchLeagues = useCallback(async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + '/api/leagues/', {
        headers: {
          'Authorization': `Token ${localStorage.getItem('authToken')}`
        },
      });
      // Filter to only include active leagues
      const activeLeagues = response.data.filter(league => league.active);
      setLeagues(activeLeagues);

      // Set first active league as default
      if (activeLeagues.length > 0) {
        setSelectedLeague(activeLeagues[0].id);
      }

      setLoading(false);
    } catch (error) {
      console.error("There was an error fetching the leagues!", error);
      setLoading(false);
    }
  }, []);

  const handleScoreUpdate = async (id, team1Score, team2Score) => {
    const match = matches.find(m => m.id === id);

    try {
      const response = await axios.patch(process.env.REACT_APP_API_URL + `/api/playoffmatches/${id}/`, {
        team1_score: team1Score,
        team2_score: team2Score,
        team1: match.team1?.id,
        team2: match.team2?.id
      }, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': getCsrfToken(),
          'Authorization': `Token ${localStorage.getItem('authToken')}`
        },
        withCredentials: true,
      });

      setMatches(prevMatches =>
        prevMatches.map(m =>
          m.id === id ? response.data : m
        )
      );
      
      // Set success message for specific match
      setSuccessMessages(prev => ({
        ...prev,
        [id]: "Resultatet har sparats!"
      }));

      // Clear the success message after 3 seconds
      setTimeout(() => {
        setSuccessMessages(prev => {
          const newMessages = { ...prev };
          delete newMessages[id];
          return newMessages;
        });
      }, 3000);
    } catch (error) {
      console.error("There was an error updating the match!", error);
      console.error("Error details:", error.response?.data);
    }
  };

  const handleMatchChange = async (matchId, teamField, selectedValue) => {
    const match = matches.find(m => m.id === matchId);

    if (teamField === 'team1' || teamField === 'team2') {
      const updateData = {
        team1_score: match.team1_score || 0,
        team2_score: match.team2_score || 0,
        team1: teamField === 'team1' ? (selectedValue ? parseInt(selectedValue) : null) : match.team1?.id,
        team2: teamField === 'team2' ? (selectedValue ? parseInt(selectedValue) : null) : match.team2?.id
      };

      try {
        const response = await axios.patch(
          process.env.REACT_APP_API_URL + `/api/playoffmatches/${matchId}/`,
          updateData,
          {
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': getCsrfToken(),
              'Authorization': `Token ${localStorage.getItem('authToken')}`
            },
            withCredentials: true,
          }
        );

        setMatches(prevMatches =>
          prevMatches.map(m =>
            m.id === matchId ? response.data : m
          )
        );
      } catch (error) {
        console.error("Error updating match:", error);
        setMatches(prevMatches =>
          prevMatches.map(m =>
            m.id === matchId ? match : m
          )
        );
      }
    } else {
      setMatches(prevMatches =>
        prevMatches.map(m =>
          m.id === matchId ? { ...m, [teamField]: selectedValue } : m
        )
      );
    }
  };

  useEffect(() => {
    fetchLeagues();
  }, [fetchLeagues]);

  useEffect(() => {
    if (leagues.length > 0) {
      fetchPlayoffMatches();
    }
  }, [leagues, fetchPlayoffMatches]);

  const getMatchTypeName = (type) => {
    switch (type) {
      case 'QF': return 'A-Slutspel - Kvartsfinal';
      case 'SF': return 'A-Slutspel - Semifinal';
      case 'F': return 'A-Slutspel - Final';
      case 'LR1': return 'B-Slutspel - Kvartsfinal';
      case 'LR2': return 'B-Slutspel - Semifinal';
      case 'LF': return 'B-Slutspel - Final';
      default: return `Match ${type}`;
    }
  };

  const MatchCard = ({ match, onSave, onTeamChange }) => {
    return (
      <div className="bg-white shadow-md p-4 mb-4 rounded-lg">
        <div className="flex flex-col">
          <div className="text-gray-700 text-sm text-center mb-2">
            {getMatchTypeName(match.match_type)} - Match {match.match_number}
          </div>

          <div className="flex flex-col items-center mb-4">
            <div className="w-full mb-4">
              <select
                className="w-full p-2 border border-gray-300 rounded-md text-gray-700"
                value={match.team1?.id || ''}
                onChange={(e) => onTeamChange(match.id, 'team1', e.target.value)}
              >
                <option value="">Välj Lag 1</option>
                {[...(groupATeams[match.league] || []), ...(groupBTeams[match.league] || [])].map(team => (
                  <option key={team.id} value={team.id}>
                    {team.team.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex items-center justify-center w-full mb-4">
              <input
                type="number"
                className="w-16 h-10 text-center border border-gray-300 rounded-md text-lg"
                placeholder="0"
                value={match.team1_score || 0}
                onChange={(e) => onTeamChange(match.id, 'team1_score', parseInt(e.target.value) || 0)}
              />
              <span className="mx-3 text-lg font-bold">-</span>
              <input
                type="number"
                className="w-16 h-10 text-center border border-gray-300 rounded-md text-lg"
                placeholder="0"
                value={match.team2_score || 0}
                onChange={(e) => onTeamChange(match.id, 'team2_score', parseInt(e.target.value) || 0)}
              />
            </div>

            <div className="w-full mb-4">
              <select
                className="w-full p-2 border border-gray-300 rounded-md text-gray-700"
                value={match.team2?.id || ''}
                onChange={(e) => onTeamChange(match.id, 'team2', e.target.value)}
              >
                <option value="">Välj Lag 2</option>
                {[...(groupATeams[match.league] || []), ...(groupBTeams[match.league] || [])].map(team => (
                  <option key={team.id} value={team.id}>
                    {team.team.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Success message for specific match */}
            {successMessages[match.id] && (
              <div className="w-full mb-4">
                <p className="text-green-500 text-center">{successMessages[match.id]}</p>
              </div>
            )}

            <button
              className="w-full bg-gray-600 text-white py-2 px-4 rounded-lg hover:bg-gray-800"
              onClick={() => onSave(match.id, match.team1_score, match.team2_score)}
            >
              Spara resultat
            </button>
          </div>
        </div>
      </div>
    );
  };

  const getLeagueMatches = (leagueId) => {
    return matches.filter(match => match.league === leagueId);
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <img
          src={require('../../../images/curling.gif')}
          alt="Loading..."
          className="w-24 h-24"
        />
      </div>
    );
  }

  return (
    <div className="p-4 md:p-6 max-w-7xl mx-auto">
      <div className="mb-6">
        <h2 className="text-2xl font-bold mb-4">Slutspelsmatcher</h2>
        <select
          className="block text-white bg-gray-800 border border-gray-300 rounded ring-blue-500 border-blue-500 text-sm p-2.5 mt-2"
          value={selectedLeague || ''}
          onChange={(e) => setSelectedLeague(e.target.value ? Number(e.target.value) : null)}
        >
          <option value="">Välj liga</option>
          {leagues.map(league => (
            <option key={league.id} value={league.id}>
              {league.name}
            </option>
          ))}
        </select>
      </div>

      {selectedLeague && (
        <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {getLeagueMatches(selectedLeague).length === 0 ? (
            <p className="text-gray-500">Inga matcher hittades för denna liga.</p>
          ) : (
            getLeagueMatches(selectedLeague).map(match => (
              <MatchCard
                key={match.id}
                match={match}
                onSave={handleScoreUpdate}
                onTeamChange={handleMatchChange}
              />
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default PlayoffMatches;