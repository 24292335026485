import React from 'react';

export function StatisticsCard({ icon, title, value }) {
    return (
        <div className="bg-white rounded-lg shadow p-3 sm:p-4 border border-gray-100">
            <div className="flex items-center space-x-3">
                <div className="p-1.5 sm:p-2 bg-blue-100 rounded-lg text-blue-600">
                    {icon}
                </div>
                <div>
                    <p className="text-xs sm:text-sm text-gray-500">{title}</p>
                    <p className="text-sm sm:text-base font-bold">{value}</p>
                </div>
            </div>
        </div>
    );
}