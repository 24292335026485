import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../components/Footer';

const MatchesPage = () => {
  const { leagueId } = useParams();
  const [leagueName, setLeagueName] = useState('');
  const [matchesByDate, setMatchesByDate] = useState({});
  const [availableDates, setAvailableDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const tableContainerRef = useRef(null);

  // Existing methods like extractTimeFromTimestamp, findNextUpcomingDate remain the same
  useEffect(() => {
    const fetchLeagueName = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/`);
        if (!response.ok) {
          throw new Error('Failed to fetch league name');
        }
        const data = await response.json();
        setLeagueName(data.name);
      } catch (error) {
        console.error('Error fetching league name:', error);
        setError(error.message);
      }
    };

    const fetchMatches = async () => {
      try {
        setLoading(true);
        const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/matches/`);
        if (!response.ok) throw new Error('Network response was not ok');

        const data = await response.json();

        // Group matches by date
        const matchesByDateObj = {};
        const uniqueDates = new Set();

        data.forEach(match => {
          const matchDate = match.date.split('T')[0];
          uniqueDates.add(matchDate);

          if (!matchesByDateObj[matchDate]) {
            matchesByDateObj[matchDate] = {};
          }

          const matchTime = extractTimeFromTimestamp(match.date);

          if (!matchesByDateObj[matchDate][matchTime]) {
            matchesByDateObj[matchDate][matchTime] = {
              1: null, 2: null, 3: null, 4: null, 5: null, 6: null
            };
          }

          matchesByDateObj[matchDate][matchTime][match.track] = match;
        });

        setMatchesByDate(matchesByDateObj);

        const sortedDates = Array.from(uniqueDates).sort((a, b) => new Date(a) - new Date(b));
        setAvailableDates(sortedDates);

        const nextDate = findNextUpcomingDate(sortedDates);
        setSelectedDate(nextDate);

      } catch (error) {
        console.error('Error fetching matches:', error);
        setError('Failed to fetch matches');
      } finally {
        setLoading(false);
      }
    };

    fetchLeagueName();
    fetchMatches();
  }, [leagueId]);

  // New useEffect for touch scrolling
  useEffect(() => {
    const container = tableContainerRef.current;
    if (!container) return;

    let startX;
    let isDown = false;
    let scrollLeft;

    const handleTouchStart = (e) => {
      isDown = true;
      container.classList.add('active');
      startX = e.touches[0].pageX - container.offsetLeft;
      scrollLeft = container.scrollLeft;
    };

    const handleTouchMove = (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.touches[0].pageX - container.offsetLeft;
      const walk = (x - startX) * 2; 
      container.scrollLeft = scrollLeft - walk;
    };

    const handleTouchEnd = () => {
      isDown = false;
      container.classList.remove('active');
    };

    container.addEventListener('touchstart', handleTouchStart);
    container.addEventListener('touchmove', handleTouchMove, { passive: false });
    container.addEventListener('touchend', handleTouchEnd);

    return () => {
      container.removeEventListener('touchstart', handleTouchStart);
      container.removeEventListener('touchmove', handleTouchMove);
      container.removeEventListener('touchend', handleTouchEnd);
    };
  }, []);

  // Existing helper methods
  const extractTimeFromTimestamp = (timestamp) => {
    if (!timestamp) return 'Unknown Time';

    try {
      const date = new Date(timestamp);
      return date.toLocaleTimeString('sv-SE', {
        hour: '2-digit',
        minute: '2-digit'
      });
    } catch (error) {
      console.error('Error parsing timestamp:', timestamp);
      return 'Unknown Time';
    }
  };

  const findNextUpcomingDate = (dates) => {
    const today = new Date();

    const exactTodayMatch = dates.find(dateStr => {
      const matchDate = new Date(dateStr);
      return matchDate.getFullYear() === today.getFullYear() &&
        matchDate.getMonth() === today.getMonth() &&
        matchDate.getDate() === today.getDate();
    });

    if (exactTodayMatch) {
      return exactTodayMatch;
    }

    const futureDates = dates.filter(dateStr => new Date(dateStr) >= today);
    return futureDates.length > 0 ? futureDates[0] : dates[0];
  };

  const truncateTeamName = (name, maxLength = 15) => {
    if (!name) return '';
    return name.length > maxLength
      ? `${name.slice(0, maxLength)}...`
      : name;
  };

  const renderMatchCell = (match, track) => {
    if (!match) return <td key={`empty-track-${track}`} className="border px-4 py-2">-</td>;

    return (
      <td key={`match-track-${track}`} className="w-36 border-collapse border border-gray-200 p-0 hover:bg-gray-100">
        <div className="flex h-12 items-center px-2 text-center text-sm font-medium bg-red-800 text-white">
          <span className="font-semibold max-w-[120px] truncate">
            ({match.team1_groupid}) {truncateTeamName(match.team1_name)}
          </span>
          <span className="ml-auto text-gray-800">
            {match.team1_score !== null ? match.team1_score : '-'}
          </span>
        </div>
        <div className="flex h-12 items-center px-2 text-center text-sm font-medium bg-yellow-300 text-gray-800">
          <span className="font-semibold max-w-[120px] truncate">
            ({match.team2_groupid}) {truncateTeamName(match.team2_name)}
          </span>
          <span className="ml-auto text-gray-800">
            {match.team2_score !== null ? match.team2_score : '-'}
          </span>
        </div>
      </td>
    );
  };

  const renderMatchesTable = () => {
    if (!selectedDate || !matchesByDate[selectedDate]) return null;

    const timeSortedMatches = Object.keys(matchesByDate[selectedDate]).sort();

    return (
      <div 
        ref={tableContainerRef}
        className="overflow-x-auto touch-pan-x"
        style={{ 
          overscrollBehaviorX: 'contain', 
          WebkitOverflowScrolling: 'touch' 
        }}
      >
        <table 
          className="w-full border-collapse border min-w-[1200px]"
          style={{ 
            touchAction: 'pan-x',
            userSelect: 'none'
          }}
        >
          <thead>
            <tr>
              <th className="px-4 py-3">Tid</th>
              {[1, 2, 3, 4, 5, 6].map(track => (
                <th key={track} className="px-4 py-3 w-[200px]">Bana {track}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {timeSortedMatches.map((time, index) => (
              <tr
                key={time}
                className={`${index % 2 === 0 ? 'bg-white text-black' : 'bg-gray-100 text-black'
                  } hover:bg-gray-200 text-black`}
              >
                <td className="border px-4 py-2 font-semibold">{time}</td>
                {[1, 2, 3, 4, 5, 6].map((track) =>
                  renderMatchCell(matchesByDate[selectedDate][time][track], track)
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <img
          src={require('../images/curling.gif')}
          alt="Loading..."
          className="w-24 h-24"
        />
      </div>
    );
  }
  
  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen text-red-600">
        Error: {error}
      </div>
    );
  }
  
  // Rendera innehållet om laddningen är klar och det inte finns några fel
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center pt-16">
      <main className="flex-grow container mx-auto px-4 py-8">
        <div className="bg-white shadow-lg rounded-lg p-6">
          <h2 className="text-2xl font-bold mb-6 text-gray-800">{leagueName}</h2>
  
          <div className="mb-6 flex items-center space-x-4">
            <div className="relative">
              <select
                id="date-select"
                value={selectedDate || ''}
                onChange={(e) => setSelectedDate(e.target.value)}
                className="block w-full text-white bg-gray-800 border border-gray-300 rounded ring-blue-500 border-blue-500 text-sm p-2.5 mt-2"
              >
                {availableDates.map((date) => (
                  <option key={date} value={date}>
                    {new Date(date).toLocaleDateString('sv-SE', {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                  </option>
                ))}
              </select>
            </div>
          </div>
  
          {renderMatchesTable()}
        </div>
      </main>
      <Footer />
    </div>
  );
}
export default MatchesPage;