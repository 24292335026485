import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Footer from '../components/Footer';

const getPlayoffRoundName = (matchType) => {
    switch (matchType) {
        case 'QF':
        case 'LR1':
            return 'Kvartsfinal';
        case 'SF':
        case 'LR2':
            return 'Semifinal';
        case 'F':
        case 'LF':
            return 'Final';
        default:
            return matchType;
    }
};

const LeagueSchedule = () => {
    const { leagueId } = useParams();
    const [leagueName, setLeagueName] = useState('');
    const [matchesByDate, setMatchesByDate] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAllData = async () => {
            try {
                setLoading(true);
                
                // Fetch league details
                try {
                    const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/`);
                    if (!response.ok) {
                        throw new Error('Failed to fetch league name');
                    }
                    const data = await response.json();
                    setLeagueName(data.name);
                } catch (error) {
                    console.error('Error fetching league name:', error);
                    setError(error.message);
                }

                // Fetch regular season matches
                const regularMatchesResponse = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/matches/`);
                if (!regularMatchesResponse.ok) throw new Error('Network response was not ok');
                const regularMatchesData = await regularMatchesResponse.json();

                // Fetch playoff matches
                let playoffMatches = [];
                try {
                    const playoffResponse = await axios.get(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/playoffmatches/`);
                    
                    // Combine all playoff matches into a single array
                    const allPlayoffMatches = [
                        ...(playoffResponse.data.quarterfinals?.matches || []),
                        ...(playoffResponse.data.semifinals?.matches || []),
                        ...(playoffResponse.data.finals?.matches || [])
                    ];

                    // Transform playoff matches to match the regular season format
                    playoffMatches = allPlayoffMatches.map(match => ({
                        date: match.match_date,
                        track: match.track,
                        team1_name: match.team1_details?.team?.name || 'TBD 1',
                        team2_name: match.team2_details?.team?.name || 'TBD 2',
                        team1_id: match.team1_details?.team?.id,
                        team2_id: match.team2_details?.team?.id,
                        is_playoff: true,
                        round: match.bracket_type === 'winner' ? 
                            `${getPlayoffRoundName(match.match_type)} - A Slutspel` : 
                            `${getPlayoffRoundName(match.match_type)} - B Slutspel`
                    }));
                } catch (error) {
                    console.error("Error fetching playoff data:", error);
                    // Continue even if playoff matches fail to load
                }

                // Combine and process all matches
                const allMatches = [...regularMatchesData, ...playoffMatches];
                const groupedMatches = processMatches(allMatches);
                setMatchesByDate(groupedMatches);
            } catch (error) {
                console.error('Error fetching league data:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchAllData();
    }, [leagueId]);

    const processMatches = (matches) => {
        const grouped = {};

        matches.forEach(match => {
            // Handle both regular matches (match.date) and playoff matches (match.match_date)
            const dateString = match.match_date || match.date;
            const date = new Date(dateString);
            
            // Format date to YYYY-MM-DD
            const dateStr = date.toLocaleDateString('sv-SE');
            
            // Format time to HH:mm
            const timeStr = date.toLocaleTimeString('sv-SE', {
                hour: '2-digit',
                minute: '2-digit'
            });

            if (!grouped[dateStr]) {
                grouped[dateStr] = {};
            }
            if (!grouped[dateStr][timeStr]) {
                grouped[dateStr][timeStr] = {
                    1: null,
                    2: null,
                    3: null,
                    4: null,
                    5: null,
                    6: null
                };
            }

            grouped[dateStr][timeStr][match.track] = {
                team1: match.team1_name,
                team2: match.team2_name,
                team1Id: match.team1_id,
                team2Id: match.team2_id,
                isPlayoff: match.is_playoff,
                round: match.round
            };
        });

        return grouped;
    };

    const renderMatchCell = (match) => {
        if (!match) {
            return (
                <td className="w-36 border-collapse border border-gray-200 p-2 text-center text-gray-800">
                </td>
            );
        }
    
        return (
            <td className="w-36 border-collapse border border-gray-200 p-0 hover:bg-gray-100">
                <div>
                    {match.isPlayoff && (
                        <div className="bg-emerald-700 text-white text-xs p-1 text-center">
                            {match.round}
                        </div>
                    )}
                    <div className="flex h-12 items-center justify-center text-center text-sm font-medium bg-red-800 text-white">
                        {match.team1Id ? (
                            <a className="hover:underline" href={`/league/${leagueId}/teams?expandedTeam=${match.team1Id}`}>
                                {match.team1}
                            </a>
                        ) : (
                            <span>{match.team1}</span>
                        )}
                    </div>
                    <div className="flex h-12 items-center justify-center text-center text-sm font-medium bg-yellow-300 text-gray-800">
                        {match.team2Id ? (
                            <a className="hover:underline" href={`/league/${leagueId}/teams?expandedTeam=${match.team2Id}`}>
                                {match.team2}
                            </a>
                        ) : (
                            <span>{match.team2}</span>
                        )}
                    </div>
                </div>
            </td>
        );
    };

    const renderDaySchedule = (date, matches) => {
        const formattedDate = new Date(date).toLocaleDateString('sv-SE', {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });

        // Sort times in ascending order
        const sortedTimes = Object.keys(matches).sort((a, b) => a.localeCompare(b));

        return (
            <div key={date}>
                <p className="text-xl text-gray-900 leading-normal font-bold text-left whitespace-normal mt-4 capitalize">
                    {formattedDate}
                </p>
                <table className="w-full table-fixed border-separate border-spacing-y-3">
                    <thead>
                        <tr>
                            <th className="w-16">
                                <p className="text-base text-white leading-normal font-bold text-left whitespace-normal">
                                    Tid
                                </p>
                            </th>
                            {[1, 2, 3, 4, 5, 6].map(sheet => (
                                <th key={sheet} className="w-36">
                                    <p className="text-base text-white leading-normal font-bold text-center whitespace-normal">
                                        Bana {sheet}
                                    </p>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {sortedTimes.map((time, index) => (
                            <tr 
                                key={time} 
                                className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'} 
                                    hover:bg-gray-200 border px-4 py-2 font-semibold`}
                            >
                                <td className="align-top">{time}</td>
                                {[1, 2, 3, 4, 5, 6].map(sheet => (
                                    <React.Fragment key={sheet}>
                                        {renderMatchCell(matches[time][sheet])}
                                    </React.Fragment>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    if (loading) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <img
                    src={require('../images/curling.gif')}
                    alt="Loading..."
                    className="w-24 h-24"
                />
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex justify-center items-center min-h-screen text-red-600">
                Error: {error}
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col items-center pt-16">
            <main className="flex-grow container mx-auto px-4 py-8">
                <div className="bg-white shadow-lg rounded-lg p-6">
                    <h2 className="text-2xl font-bold mb-6 text-gray-800">Spelschema {leagueName}</h2>
                    <div className="print overflow-x-auto print:overflow-x-visible">
                        {Object.entries(matchesByDate).map(([date, matches]) =>
                            renderDaySchedule(date, matches)
                        )}
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default LeagueSchedule;