import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState('');
    const [loginStep, setLoginStep] = useState('initial'); // initial, requestOtp, verifyOtp
    const { setIsLoggedIn, setUser } = useAuth();
    const navigate = useNavigate();
    const videoRef = useRef(null);
    const endTime = 3; // Pausa vid 3 sekunder

    useEffect(() => {
        const video = videoRef.current;
        if (video) {
            // Försök spela upp videon när komponenten laddas
            const playVideo = async () => {
                try {
                    await video.play();
                } catch (err) {
                    console.log("Video autoplay failed:", err);
                }
            };

            video.currentTime = 0;
            playVideo();

            video.addEventListener('timeupdate', () => {
                if (video.currentTime >= endTime) {
                    video.pause();
                }
            });
        }
    }, []);


    const handlePasswordLogin = async (e) => {
        e.preventDefault();
        setError(null);
    
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/api/login/', {
                username,
                password,
            });
    
            const token = response.data.token;
            localStorage.setItem('authToken', token);
            setIsLoggedIn(true);
    
            // Fetch user data
            const userResponse = await axios.get(process.env.REACT_APP_API_URL + '/api/auth/custom-user/', {
                headers: {
                    Authorization: `Token ${token}`,
                },
            });
    
            // Fetch permissions
            const permissionsResponse = await axios.get(process.env.REACT_APP_API_URL + '/api/auth/get-user-permissions/', {
                headers: {
                    Authorization: `Token ${token}`,
                },
            });
    
            const userData = userResponse.data;
            userData.permissions = permissionsResponse.data;
    
            setUser(userData);
            navigate('/profile');
    
        } catch (error) {
            setError(error.response?.data?.error || 'Inloggning misslyckades.');
        }
    };

    const handleRequestOtp = async (e) => {
        e.preventDefault();
        setError(null);

        try {
            await axios.post(process.env.REACT_APP_API_URL + '/api/users/request-otp/', {
                email: email.toLowerCase()
            });
            setSuccess('En engångskod har skickats till din e-post.');
            setLoginStep('verifyOtp');
        } catch (error) {
            setError(error.response?.data?.error || 'Kunde inte skicka engångskod.');
        }
    };

    const handleVerifyOtp = async (e) => {
        e.preventDefault();
        setError(null);

        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/api/users/verify-otp/', {
                email: email.toLowerCase(),
                otp
            });

            const token = response.data.token;
            localStorage.setItem('authToken', token);
            setIsLoggedIn(true);

            // Fetch user data
            const userResponse = await axios.get(process.env.REACT_APP_API_URL + '/api/auth/custom-user/', {
                headers: {
                    Authorization: `Token ${token}`,
                },
            });

            // Fetch permissions
            const permissionsResponse = await axios.get(process.env.REACT_APP_API_URL + '/api/auth/get-user-permissions/', {
                headers: {
                    Authorization: `Token ${token}`,
                },
            });

            setUser({
                ...userResponse.data,
                permissions: permissionsResponse.data
            });

            navigate('/profile');

        } catch (error) {
            setError(error.response?.data?.error || 'Verifiering av engångskod misslyckades.');
        }
    };

    const renderPasswordForm = () => (
        <form onSubmit={handlePasswordLogin} className="space-y-4">
            <div>
                <label className="block text-sm font-medium text-gray-700">
                    Användarnamn:
                </label>
                <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700">
                    Lösenord:
                </label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
            </div>
            <button
                type="submit"
                className="w-full bg-gray-600 text-white py-2 px-4 rounded-md hover:bg-gray-700 transition-colors"
            >
                Logga in
            </button>
        </form>
    );

    const renderRequestOtpForm = () => (
        <form onSubmit={handleRequestOtp} className="space-y-4">
            <p className="text-gray-600 mb-4">
                Ange din e-postadress för att få en engångskod.
            </p>
            <div>
                <label className="block text-sm font-medium text-gray-700">
                    E-post:
                </label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
            </div>
            <button
                type="submit"
                className="w-full bg-gray-600 text-white py-2 px-4 rounded-md hover:bg-gray-700 transition-colors"
            >
                Skicka engångskod
            </button>
        </form>
    );

    const renderVerifyOtpForm = () => (
        <form onSubmit={handleVerifyOtp} className="space-y-4">
            <p className="text-gray-600 mb-4">
                Ange den engångskod du fått via e-post.
            </p>
            <div>
                <label className="block text-sm font-medium text-gray-700">
                    Engångskod:
                </label>
                <input
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
            </div>
            <button
                type="submit"
                className="w-full bg-gray-600 text-white py-2 px-4 rounded-md hover:bg-gray-700 transition-colors"
            >
                Verifiera kod
            </button>
        </form>
    );

    return (
        <div className="min-h-screen flex items-center justify-center relative overflow-hidden">
            <video
                ref={videoRef}
                autoPlay
                muted
                playsInline
                className="absolute w-full h-full object-cover"
                style={{ zIndex: -1 }}
            >
                <source src={require('../images/video.mp4')} type="video/mp4" />
            </video>
            <div className="absolute inset-0 bg-black opacity-50" style={{ zIndex: -1 }}></div>

            <div className="w-full max-w-md bg-white shadow-md rounded-lg p-8 relative z-10">
                <h2 className="text-2xl font-bold mb-6 text-center">Logga in</h2>

                {error && <p className="text-red-500 text-center mb-4">{error}</p>}
                {success && <p className="text-green-500 text-center mb-4">{success}</p>}

                {loginStep === 'initial' && (
                    <div className="space-y-6">
                        {renderPasswordForm()}
                        <div className="relative">
                            <div className="absolute inset-0 flex items-center">
                                <div className="w-full border-t border-gray-300" />
                            </div>
                            <div className="relative flex justify-center text-sm">
                                <span className="px-2 bg-white text-gray-500">Eller</span>
                            </div>
                        </div>
                        <button
                            onClick={() => setLoginStep('requestOtp')}
                            className="w-full bg-gray-50 text-gray-700 py-2 px-4 rounded-md border border-gray-300 hover:bg-gray-100 transition-colors"
                        >
                            Logga in med engångskod
                        </button>
                    </div>
                )}

                {loginStep === 'requestOtp' && renderRequestOtpForm()}
                {loginStep === 'verifyOtp' && renderVerifyOtpForm()}

                {loginStep !== 'initial' && (
                    <button
                        onClick={() => {
                            setLoginStep('initial');
                            setError(null);
                            setSuccess('');
                        }}
                        className="mt-4 text-indigo-600 hover:underline text-sm w-full text-center"
                    >
                        Tillbaka till vanlig inloggning
                    </button>
                )}
            </div>
        </div>
    );
};

export default LoginPage;