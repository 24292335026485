import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Calendar, User, Info, Trophy } from 'lucide-react';
import Footer from '../components/Footer';


const Home = () => {
  const [leagues, setLeagues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showMessage, setShowMessage] = useState(false);


  useEffect(() => {
    const today = new Date();
    const endDate = new Date("2025-01-10");
    setShowMessage(today <= endDate);
  
    const fetchLeagues = async () => {
      try {
        setLoading(true);
        const response = await fetch(process.env.REACT_APP_API_URL + '/api/leagues/');
        if (!response.ok) {
          throw new Error('Failed to fetch leagues');
        }
        const data = await response.json();
  
        // Loop through each league to fetch teams for both groups and count them
        const updatedLeagues = await Promise.all(
          data.map(async (league) => {
            const groupATeams = await fetchTeamsInGroup(league.id, 'A');
            const groupBTeams = await fetchTeamsInGroup(league.id, 'B');
            const totalTeams = (groupATeams?.length || 0) + (groupBTeams?.length || 0);
            return { ...league, totalTeams };
          })
        );
  
        setLeagues(updatedLeagues);
  
        // Filter archived leagues
        const archivedLeagues = updatedLeagues.filter(league => !league.active);
  
        // Fetch results for archived leagues
        await fetchLeagueResults(archivedLeagues);
  
      } catch (err) {
        setError(err.message);
        console.error('Error fetching leagues:', err);
      } finally {
        setLoading(false);
      }
    };

    const fetchTeamsInGroup = async (leagueId, group) => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/teams/?group=${group}`);
        if (!response.ok) {
          throw new Error(`Failed to fetch teams for group ${group} in league ${leagueId}`);
        }
        const data = await response.json();
        return data;
      } catch (error) {
        //console.error(`Error fetching teams in group ${group} for league ${leagueId}:`, error);
        return [];
      }
    };

    const fetchLeagueResults = async (leagues) => {
      try {
        // Om leagues är tomma eller undefined, avbryt funktionen
        if (!leagues || leagues.length === 0) {
          console.log('No archived leagues to fetch results for');
          return;
        }
    
        // Skapa en URL med alla arkiverade liga-ID:n
        const leagueIds = leagues.map(league => league.id).join(',');
    
        // Hämta resultat för Playoff A, specifikt för arkiverade ligor
        const responseA = await fetch(
          `${process.env.REACT_APP_API_URL}/api/league-results/?playoff_group=A&league_ids=${leagueIds}`
        );
    
        if (!responseA.ok) {
          throw new Error('Failed to fetch league results');
        }
    
        const playoffAResults = await responseA.json();
    
        // Skapa ett objekt för enkel åtkomst
        const resultsMap = playoffAResults.reduce((acc, leagueResult) => {
          // Hitta vinnaren (första plats)
          const winner = leagueResult.results.find(result => result.finalplacement === 1);
    
          acc[leagueResult.league_id] = {
            playoffA: leagueResult.results,
            winner: winner ? winner.team.name : null
          };
    
          return acc;
        }, {});
    
        // Uppdatera leagues med vinnare
        setLeagues(prevLeagues => 
          prevLeagues.map(league => {
            const leagueResult = resultsMap[league.id];
            return leagueResult
              ? { ...league, winner: leagueResult.winner }
              : league;
          })
        );
    
      } catch (error) {
        console.error('Fel vid hämtning av resultat:', error);
        // Här kan du lägga till ytterligare felhantering, t.ex. att sätta ett error state
        setError('Could not fetch league results');
      }
    };

    fetchLeagues();
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <img
          src={require('../images/curling.gif')}
          alt="Loading..."
          className="w-24 h-24"
        />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-xl text-red-600">Error: {error}</div>
      </div>
    );
  }
  const archivedLeagues = leagues.filter(league => !league.active);
  const activeLeagues = leagues.filter(league => league.active);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <main className="max-w-7xl mx-auto px-4 py-16 pt-16 space-y-16 flex-grow">
        {/* Aktiva tävlingar sektion */}
        <section className='pt-10'>
          {activeLeagues.length === 0 ? (
            <div className="text-center text-gray-600">Just nu pågår inga aktiva tävlingar.</div>
          ) : (

            <section>
              <h2 className="text-2xl font-bold mb-6">Aktiva tävlingar</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">

                {activeLeagues.map((league) => (
                  <Link to={`/league/${league.id}`} key={league.id} className="h-full">
                    <div className="w-full h-full bg-white rounded-xl overflow-hidden shadow-lg transition-all duration-300 hover:shadow-xl flex flex-col">
                      <div className="bg-gray-800 px-6 py-4">
                        <h2 className="text-xl font-bold text-white">{league.name}</h2>
                      </div>

                      <div className="p-6 space-y-4 flex-grow flex flex-col">
                        <div className="flex items-start space-x-3">
                          <User className="w-5 h-5 text-gray-900 mt-0.5 flex-shrink-0" />
                          <div>
                            <p className="text-sm text-gray-500">Arrangör</p>
                            <p className="text-gray-900 font-medium">{league.organizer}</p>
                          </div>
                        </div>

                        <div className="flex items-start space-x-3">
                          <Info className="w-5 h-5 text-Gray-900 mt-0.5 flex-shrink-0" />
                          <div>
                            <p className="text-sm text-gray-500">Information</p>
                            <p className="text-gray-900">{league.info}</p>
                          </div>
                        </div>

                        <div className="flex items-start space-x-3">
                          <Calendar className="w-5 h-5 text-gray-900 mt-0.5 flex-shrink-0" />
                          <div>
                            <p className="text-sm text-gray-500">Datum</p>
                            <p className="text-gray-900 font-medium">
                              {new Date(league.start_date).toLocaleDateString()} - {new Date(league.end_date).toLocaleDateString()}
                            </p>
                          </div>
                        </div>

                        <div className="flex space-x-3 mt-auto">
                          <span className="inline-block px-2 py-1 text-sm bg-green-100 text-green-800 rounded">
                            Aktiv
                          </span>
                          <span className="inline-block px-2 py-1 text-sm bg-green-100 text-green-800 rounded">
                            Antal lag: {league.totalTeams}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </section>
          )}
        </section>

        {/* Kommande tävlingar */}
        {showMessage && (
          <section>
            <h2 className="text-2xl font-bold mb-6">Kommande tävling</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              <div className="w-full h-full bg-white rounded-xl overflow-hidden shadow-lg transition-all duration-300 hover:shadow-xl flex flex-col">
                <div className="bg-gray-800 px-6 py-4">
                  <h2 className="text-xl font-bold text-white">Klubbmästerskap 2025</h2>
                </div>

                <div className="p-6 space-y-4 flex-grow flex flex-col">
                  <div className="flex items-start space-x-3">
                    <User className="w-5 h-5 text-gray-900 mt-0.5 flex-shrink-0" />
                    <div>
                      <p className="text-sm text-gray-500">Arrangör</p>
                      <p className="text-gray-900 font-medium">Härnösands Curlingklubb</p>
                    </div>
                  </div>

                  <div className="flex items-start space-x-3">
                    <Info className="w-5 h-5 text-gray-900 mt-0.5 flex-shrink-0" />
                    <div>
                      <p className="text-sm text-gray-500">Information</p>
                      <p className="text-gray-900">
                        Anmälan är nu öppen till KM 2025, anmäl ditt lag till mathias.mabergs@hotmail.se eller via listorna i hallen.
                        För mer information, se mailet som har gått ut.
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start space-x-3">
                    <Calendar className="w-5 h-5 text-gray-900 mt-0.5 flex-shrink-0" />
                    <div>
                      <p className="text-sm text-gray-500">Anmälningsdatum:</p>
                      <p className="text-gray-900 font-medium">Senast Fredag 20 december</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {/* Avslutade tävlingar sektion */}
        {archivedLeagues.length > 0 && (
          <section>
            <h2 className="text-2xl font-bold mb-6">Avslutade tävlingar</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {archivedLeagues.map((league) => (
                <Link to={`/league/${league.id}`} key={league.id} className="h-full">
                  <div className="w-full h-full bg-white rounded-xl overflow-hidden shadow-lg transition-all duration-300 hover:shadow-xl flex flex-col">
                    <div className="bg-gray-800 px-6 py-4">
                      <h2 className="text-xl font-bold text-white">{league.name}</h2>
                    </div>

                    <div className="p-6 space-y-4 flex-grow flex flex-col">
                      <div className="flex items-start space-x-3">
                        <User className="w-5 h-5 text-gray-900 mt-0.5 flex-shrink-0" />
                        <div>
                          <p className="text-sm text-gray-500">Arrangör</p>
                          <p className="text-gray-900 font-medium">{league.organizer}</p>
                        </div>
                      </div>

                      <div className="flex items-start space-x-3">
                        <Trophy className="w-5 h-5 text-Gray-900 mt-0.5 flex-shrink-0" />
                        <div>
                          <p className="text-sm text-gray-500">Vinnare</p>
                          <p className="text-gray-900">{league.winner || 'Ingen vinnare registrerad'}</p>
                        </div>
                      </div>

                      <div className="flex items-start space-x-3">
                        <Calendar className="w-5 h-5 text-gray-900 mt-0.5 flex-shrink-0" />
                        <div>
                          <p className="text-sm text-gray-500">Datum</p>
                          <p className="text-gray-900 font-medium">
                            {new Date(league.start_date).toLocaleDateString()} - {new Date(league.end_date).toLocaleDateString()}
                          </p>
                        </div>
                      </div>

                      <div className="flex space-x-3 mt-auto">
                        <span className="inline-block px-2 py-1 text-sm bg-red-100 text-red-800 rounded">
                          Avslutad
                        </span>
                        <span className="inline-block px-2 py-1 text-sm bg-red-100 text-red-800 rounded">
                          Antal lag: {league.totalTeams}
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </section>
        )}
      </main>

      <Footer />
    </div>
  );
};

export default Home;