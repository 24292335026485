import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white w-full mt-auto">
      <div className="container mx-auto p-4 flex flex-col items-center space-y-2">
        <p>&copy; 2025 SweepScore</p>
        <p className="text-sm">
          Vid frågor kontakta: <a href="mailto:Tobbe.stenberg@hotmail.com" className="hover:text-gray-300 underline">Tobbe.stenberg@hotmail.com</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;