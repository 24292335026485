import React from 'react';
import { X, TrendingUp } from 'lucide-react';

const TeamPopup = ({ team, matches, onClose }) => {
  const calculateTeamForm = (matches, teamName) => {
    if (!matches) return [];

    const playedMatches = matches.filter(match =>
      match.status === 'completed' &&
      (match.home_score !== 0 || match.away_score !== 0) &&
      match.home_score !== null &&
      match.away_score !== null
    );

    const sortedMatches = playedMatches.sort((a, b) =>
      new Date(b.date) - new Date(a.date)
    );

    const recentMatches = sortedMatches.slice(0, 5);

    return recentMatches.map(match => {
      const isHomeTeam = match.home_team.name === teamName;
      const teamScore = isHomeTeam ? match.home_score : match.away_score;
      const opponentScore = isHomeTeam ? match.away_score : match.home_score;

      if (teamScore > opponentScore) return 'W';
      if (teamScore < opponentScore) return 'L';
      return 'D';
    }).reverse();
  };

  const getFormColor = (result) => {
    switch (result) {
      case 'W': return 'bg-green-500';
      case 'L': return 'bg-red-500';
      case 'D': return 'bg-yellow-500';
      default: return 'bg-gray-300';
    }
  };

  const teamForm = calculateTeamForm(matches, team.name);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="p-4 border-b border-gray-200 flex justify-between items-center">
          <h2 className="text-xl font-bold">{team.name}</h2>
          <button onClick={onClose} className="p-1 hover:bg-gray-100 rounded-full">
            <X className="w-6 h-6" />
          </button>
        </div>

        <div className="p-4">
          {/* Form Display */}
          <div className="flex items-center gap-2 mb-4 bg-gray-50 p-3 rounded-lg">
            <TrendingUp className="w-4 h-4 text-gray-600" />
            <span className="font-semibold">Form:</span>
            <div className="flex gap-1">
              {teamForm.map((result, index) => (
                <span
                  key={index}
                  className={`w-6 h-6 ${getFormColor(result)} rounded-full flex items-center justify-center text-white text-sm font-bold`}
                >
                  {result}
                </span>
              ))}
            </div>
          </div>

          {/* Matches */}
          <div className="space-y-2">
            {matches?.slice(0, 10).map(match => (
              <div key={match.id} className="p-3 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors">
                {/* Desktop layout */}
                <div className="hidden md:flex items-center w-full">
                  <div className="flex-1 text-right">
                    <span className={`text-sm truncate block ${match.home_team.name === team.name ? 'font-medium' : ''}`}>
                      {match.home_team.name}
                    </span>
                  </div>

                  <div className="flex flex-col items-center justify-center mx-8 min-w-[120px]">
                    <div className="flex items-center space-x-3">
                      <span className="text-lg font-semibold">
                        {match.status === 'completed' ? match.home_score : '-'}
                      </span>
                      <span className="text-gray-400 font-medium text-sm">vs</span>
                      <span className="text-lg font-semibold">
                        {match.status === 'completed' ? match.away_score : '-'}
                      </span>
                    </div>
                  </div>

                  <div className="flex-1 text-left">
                    <span className={`text-sm truncate block ${match.away_team.name === team.name ? 'font-medium' : ''}`}>
                      {match.away_team.name}
                    </span>
                  </div>
                </div>

                {/* Mobile layout */}
                <div className="md:hidden flex flex-col items-center space-y-2">
                  <span className={`text-sm text-center ${match.home_team.name === team.name ? 'font-medium' : ''}`}>
                    {match.home_team.name}
                  </span>
                  <div className="flex flex-col items-center">
                    <span className="font-bold text-lg">
                      {match.status === 'completed'
                        ? `${match.home_score} - ${match.away_score}`
                        : 'vs'}
                    </span>
                  </div>
                  <span className={`text-sm text-center ${match.away_team.name === team.name ? 'font-medium' : ''}`}>
                    {match.away_team.name}
                  </span>
                  <span className="text-xs text-gray-500">
                    {new Date(match.date).toLocaleDateString()}
                  </span>
                </div>

                <div className="hidden md:block text-center text-xs text-gray-500 mt-1">
                  {new Date(match.date).toLocaleDateString()}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamPopup;