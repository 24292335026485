import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Users, Trophy, UserPlus, UserMinus } from 'lucide-react';

const AllTeams = () => {
  const [teams, setTeams] = useState([]);
  const [expandedTeamId, setExpandedTeamId] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/api/teams/', {
          headers: {
            'Authorization': `Token ${localStorage.getItem('authToken')}`,
          },
        });
        // Sort teams alphabetically by name
        const sortedTeams = response.data.sort((a, b) => 
          a.name.localeCompare(b.name, 'sv')
        );
        setTeams(sortedTeams);
      } catch (error) {
        console.error('Error fetching teams:', error);
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/api/users/', {
          headers: {
            'Authorization': `Token ${localStorage.getItem('authToken')}`,
          },
        });
        // Sort users alphabetically by first name and last name
        const sortedUsers = response.data.sort((a, b) => 
          `${a.first_name} ${a.last_name}`.localeCompare(`${b.first_name} ${b.last_name}`, 'sv')
        );
        setUsers(sortedUsers);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchTeams();
    fetchUsers();
  }, []);

  const handleToggleExpand = async (teamId) => {
    if (expandedTeamId === teamId) {
      setExpandedTeamId(null);
    } else {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/api/teams/${teamId}/`, {
          headers: {
            'Authorization': `Token ${localStorage.getItem('authToken')}`,
          },
        });
        const updatedTeams = teams.map(team =>
          team.id === teamId ? { ...team, active_leagues: response.data.active_leagues } : team
        );
        setTeams(updatedTeams);
        setExpandedTeamId(teamId);
      } catch (error) {
        console.error('Error fetching team details:', error);
      }
    }
  };

  const handleAddMember = async (teamId) => {
    try {
      await axios.post(process.env.REACT_APP_API_URL + `/api/teams/${teamId}/add-member/`, {
        user_id: selectedUser,
        role: 'Spelare',
      }, {
        headers: {
          'Authorization': `Token ${localStorage.getItem('authToken')}`,
        },
      });
  
      const updatedTeamsResponse = await axios.get(process.env.REACT_APP_API_URL + '/api/teams/', {
        headers: {
          'Authorization': `Token ${localStorage.getItem('authToken')}`,
        },
      });
      // Sort teams after update
      const sortedTeams = updatedTeamsResponse.data.sort((a, b) => 
        a.name.localeCompare(b.name, 'sv')
      );
      setTeams(sortedTeams);
    } catch (error) {
      console.error('Error adding team member:', error);
    }
  };

  const handleRemoveMember = async (teamId, username) => {
    try {
      await axios.delete(process.env.REACT_APP_API_URL + `/api/teams/${teamId}/remove-member/`, {
        headers: {
          'Authorization': `Token ${localStorage.getItem('authToken')}`,
        },
        data: { username }
      });

      // Refresh teams data after removing member
      const updatedTeamsResponse = await axios.get(process.env.REACT_APP_API_URL + '/api/teams/', {
        headers: {
          'Authorization': `Token ${localStorage.getItem('authToken')}`,
        },
      });
      // Sort teams after update
      const sortedTeams = updatedTeamsResponse.data.sort((a, b) => 
        a.name.localeCompare(b.name, 'sv')
      );
      setTeams(sortedTeams);
    } catch (error) {
      console.error('Error removing team member:', error);
    }
  };

  // Sort team members before rendering
  const getSortedMembers = (members) => {
    return [...members].sort((a, b) => 
      `${a.first_name} ${a.last_name}`.localeCompare(`${b.first_name} ${b.last_name}`, 'sv')
    );
  };

  return (
    <div className="container mx-auto py-6 px-4">
      <div className="flex items-center gap-2 mb-6">
        <Users className="h-6 w-6" />
        <h1 className="text-2xl font-bold">Lag:</h1>
      </div>

      <div className="space-y-4">
        {teams.map((team) => (
          <div key={team.id} className="border rounded-lg bg-white shadow-sm">
            <button
              className="flex justify-between items-center w-full px-4 py-2 text-left hover:bg-gray-100"
              onClick={() => handleToggleExpand(team.id)}
            >
              <div className="flex items-center gap-4">
                <span className="text-xl font-semibold">{team.name}</span>
                <span className="text-sm text-gray-500">{team.members.length} Spelare</span>
              </div>
            </button>

            {expandedTeamId === team.id && (
              <div className="px-4 pb-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="p-4 border rounded-md bg-gray-50">
                    <div className="flex items-center gap-2 mb-4">
                      <Users className="h-4 w-4" />
                      <h3 className="font-semibold">Spelare</h3>
                    </div>
                    <div className="space-y-2">
                      {getSortedMembers(team.members).map((member) => (
                        <div key={member.id} className="flex justify-between items-center p-2 rounded-md bg-gray-100">
                          <div>
                            <span className="font-medium">{member.first_name} {member.last_name}</span>
                            <span className="text-sm text-gray-500 ml-2">({member.username})</span>
                          </div>
                          <button 
                            onClick={() => handleRemoveMember(team.id, member.username)}
                            className="text-red-500 hover:text-red-700"
                          >
                            <UserMinus className="h-4 w-4" />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="p-4 border rounded-md bg-gray-50">
                    <div className="space-y-6">
                      <div>
                        <div className="flex items-center gap-2 mb-4">
                          <Trophy className="h-4 w-4" />
                          <h3 className="font-semibold">Aktiva serier</h3>
                        </div>
                        <div className="space-y-1">
                          {team.active_leagues && team.active_leagues.map((league) => (
                            <div key={league.id} className="p-2 rounded-md bg-gray-100">
                              {league.name}
                            </div>
                          ))}
                        </div>
                      </div>

                      <div>
                        <div className="flex items-center gap-2 mb-4">
                          <UserPlus className="h-4 w-4" />
                          <h3 className="font-semibold">Lägg till spelare</h3>
                        </div>
                        <div className="flex gap-2">
                          <select
                            value={selectedUser}
                            onChange={(e) => setSelectedUser(e.target.value)}
                            className="border border-gray-300 rounded-md p-2 mr-2 flex-grow"
                          >
                            <option value="" disabled>Välj spelare</option>
                            {users.map((user) => (
                              <option key={user.id} value={user.id}>
                                {user.first_name} {user.last_name} - ({user.username})
                              </option>
                            ))}
                          </select>
                          <button
                            onClick={() => handleAddMember(team.id)}
                            className="bg-gray-600 text-white rounded-md p-2 hover:bg-gray-800 w-40"
                          >
                            Spara
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllTeams;